@import "../Base/typography";

/** ************* Cours-nav ***************/

.breadcrumb-links {
  color: $Color-Neutral-3;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: $Color-Neutral-7;
  }
  &.active {
    color: $Color-White;
  }
  &.hr-separator {
    height: 17px !important;
    border: 0.5px solid $Color-Neutral-5;
    color: $Color-Neutral-5;
    margin: 0px 24px;
    opacity: 1;
  }
}

.cours-nav {
  background-color: $Color-Black-800;
}

.main-navbar {
  background-color: $Color-White;
  box-shadow: inset 0px -1px 0px #f5f5f6;
  height: 92px;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 200;
}

.navbar-content {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  &.responsive {
    grid-template-columns: 1fr 1fr !important;
  }
}

.navbar-submenu-list {
  background-color: $Color-White;
  box-shadow: 2px 6px 6px rgba(39, 39, 39, 0.19);
  border-radius: 3px;
  border: 1px solid $Color-Neutral-7;
  width: 242px;
  &.group-list {
    width: 207px!important;
    box-shadow: 2px 6px 6px rgba(218, 218, 218, 0.25);
  }
  &-button {
    color: $Color-Neutral-3;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $Color-Neutral-6;
      color: $Color-Neutral-3;
    }
    &.return {
      color: $Color-Black-800;
    }
  }
}

.navbar-user-info {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.navbar-submenu-list {
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 70px;
  z-index: 500;
  &.close {
    display: none;
  }
}

.navbar-submenu-list2 {
  user-select: none;
  cursor: pointer;



  &.close {
    display: none;
  }
}

.table-submenu-list {
  background-color: $Color-White;
  box-shadow: 2px 6px 6px rgba(39, 39, 39, 0.19);
  border-radius: 3px;
  border: 1px solid $Color-Neutral-7;
  width: 242px;
  &.group-list {
    width: 207px!important;
    box-shadow: 2px 6px 6px rgba(218, 218, 218, 0.25);
  }
  &-button {
    color: $Color-Neutral-3;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $Color-Neutral-6;
      color: $Color-Neutral-3;
    }
  }
}
.table-submenu-list {
  user-select: none;
  cursor: pointer;
  position:absolute;
  top: 20px;
  right: 8px;
  z-index: 500;
  &.close {
    display: none;
  }
}

.navbar-submenu-list-background {
  background: rgba(33, 33, 33, 0.5);
  z-index: 100 !important;
  position: fixed !important;
  inset: 0px !important;
  overflow-y: auto !important;
  animation-duration: 400ms !important;
  animation-iteration-count: 1 !important;
  animation-fill-mode: both !important;
  animation-name: keyframe_15p0df !important;
  &.close {
    display: none;
  }
}

@keyframes keyframe_15p0df {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes keyframe_d37zz3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar-toggler {
  &:focus {
    box-shadow: none !important;
  }
}
.nav-check {
  display: none;
}

.navbar-user-info {
  min-width: max-content;
}

.navbar-links {
  flex-grow: 1;
  align-items: center;
}

.bar {
  display: block;
  width: 24px;
  height: 3px;
  margin: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: $Color-Black-800;
}

.close-hamburger {
  color: $Color-Black-800;
  display: none;
  cursor: pointer;
}
.hamburger {
  cursor: pointer;
}

#hamburgerButton {
  display: none;
  cursor: pointer;
  user-select: none;
}
.main-navbar-responsive {
  height: 72px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.hr-nav {
  background-color: $Color-Neutral-3;
}

.navbar-links-resposive {
  position: absolute;
  top: 72px;
  left: 0;
  height: auto;
  width: 100%;
  padding: 24px;
  background-color: $Color-White;
  overflow-y: auto;
  flex-direction: column !important;
  justify-content: flex-start !important;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  display: block !important;
}

#hamburgerButton:checked ~ .close-hamburger {
  display: flex;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  user-select: none;
}
#hamburgerButton:checked ~ .hamburger {
  display: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  user-select: none;
}

.navbar-links > .navbar-liste {
  width: 100%;
  justify-content: flex-start !important;
  flex-direction: column;
}
.navbar-liste-btn-responsive > a {
  margin: 4px 0px;
  font-family: $FONT_FAMILY_PUBLIC !important;
  font-size: 18px !important;
  line-height: 32px !important;
  font-weight: 700 !important;
  background-color: $Color-Neutral-5 !important;
  color: $Color-Black-800 !important;
  border-radius: 4px !important;
  padding: 10px 16px;
  &:hover {
    background-color: $Color-Neutral-4 !important;
  }
}

.hide {
  display: none !important;
}

.top-bar-notification {
  background-color: $Color-Purple-200;
  display: flex;
  justify-content: center;
}

.top-bar-notification-content {
  margin-right: 24px;
  text-align: center;
}

.top-bar-notification-action {
  margin-right: 16px;
}

@media (max-width: 506px) {
  .top-bar-notification-content {
    margin-right: 0px;
  }
  .top-bar-notification-action {
    margin-right: 0px;
  }
}

.submenu-list {
  background-color: $Color-White;
  box-shadow: 2px 6px 6px rgba(39, 39, 39, 0.19);
  border-radius: 3px;
  border: 1px solid $Color-Neutral-7;
  width: 242px;
  &.group-list {
    width: 207px!important;
    box-shadow: 2px 6px 6px rgba(218, 218, 218, 0.25);
  }
  &-button {
    color: $Color-Neutral-3;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: $Color-Black-800;
    }
    &.return {
      color: $Color-Black-800;
    }
    transition: all .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
  }
  
}