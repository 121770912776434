.header-school-preview {
    &-default-cover {
        background-color: #272727;
        width: 100%;
        height: 160px;
    }

    &-profile-img {
        width: 146px;
        height: 145px;
        border-radius: 50%;
        margin: -90px auto 20px auto;
    }

    &-profile-img-isexiste {
        display: flex;
        justify-content: center;
        margin-top: -83px;
    }
}

#school-description {
    word-wrap: break-word;
    font-size: 20;
}