@import "../Base/typography";
@import "../Base/variables";

.activity-card {
  border: 1px solid $Color-Neutral-5;
  box-sizing: border-box;
  padding: 32px;
  padding-top: 23px;
  padding-bottom: 22px;
  min-height: 110px;
  border-radius: 3px;
  &.small {
    max-width: 282px;
    min-width: 156.56px;
    &.cours {
      max-width: 384px;
    }
  }
  &.large {
    max-width: 588px;
    &.cours {
      max-width: 486px;
    }
  }
  &.padding-perso {
    padding-right: 16px !important;
  }
}

.min-content {
  height: min-content;
}

.icon-info {
  transform: rotate(180deg);
  height: min-content;
  color: $Color-Neutral-3;
}

@media (max-width: 770px) {
  .main-list-activities {
    flex-wrap: wrap;
    justify-content: center;
  }
  .main-card-list-activities {
    margin-right: 0px !important;
  }
  .skeleton-main {
    margin-bottom: 24px;
    width: 282px !important;
  }
  .skeleton-first {
    margin-right: 24px;
  }
  .skeleton-rect {
    width: 282px !important;
  }
}

@media (max-width: 620px) {
  .second-list-activities {
    flex-wrap: wrap;
    justify-content: center;
  }
  .second-card-list-activities {
    margin-right: 0px !important;
    width: 282px !important;
  }
  .main-card-list-activities-end {
    width: 282px !important;
  }
}

.py-18 {
  padding-bottom: 18px;
  padding-top: 18px;
}

.grid-activity {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 24px 24px;
  box-sizing: border-box;
  align-content: stretch;
  padding: 0 0px 24px;
  width: 100%;
  &.end-list {
    padding-bottom: 0px;
  }
}
