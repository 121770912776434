.bg-accordion-border {
  border: 1px solid $Color-Neutral-5;
  border-radius: 3px;
  box-sizing: border-box;
  &.white {
    background-color: $Color-White;
  }
  &.accordion-FAQ {
    border-bottom: none;
    border-top: 1px solid $Color-Neutral-5;
    border-left: none;
    border-right: none;
    cursor: pointer;
    &.inner {
      border-top: none;
      border-bottom: 1px solid $Color-Neutral-5;
    }
    &.loading {
      cursor: default;
    }
  }
  &.accordion-body {
    border-top: none;
    border-radius: 0 0 3px 3px;
    padding: 40px 32px 24px 32px;
  }
  &.accordion-head-open {
    border-radius: 3px 3px 0 0;
  }
}

.accordion-faq-body {
  transition: 0.1s all ease-in-out 1ms;
  -webkit-transition: max-height 0.3s;
  .hide {
    display: none;
    transition: 0.1s all ease-in-out 1ms;
    -webkit-transition: max-height 0.3s;
  }
}

.accordionItem {
  height: auto;
  overflow: hidden;

  max-height: 50em;
  transition: max-height 1s;

  @media screen and (min-width: 48em) {
    max-height: 15em;
    transition: max-height 0.5s;
  }
}
