@import "../Base/variables";
@import "../Base/typography";

.card {
  border: none;
  border-radius: 3px;
}

.number {
  @extend .H4-Subtitle;
}

.card-body {
  border-radius: 0 0 3px 3px !important;

  &.horiz {
    border-radius: 0 3px 3px 0 !important;
  }
}

.card-text {
  &.name {
    color: $Color-Black-800;
  }

  &.title-name {
    color: $Color-Neutral-3;
  }

  &.content {
    color: $Color-Neutral-2;
  }
}

.details {
  color: $Color-Black-800;
}

.icon-grey {
  color: $Color-Neutral-3;
}

.img-card-horiz {
  width: 360px;
  min-width: 360px;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.img-card {
  position: relative;
  height: 220px;
  object-fit: cover;
}

.rating {

  span,
  label {
    margin-right: 2px;
  }

  &.on {
    color: $Color-Orange-800;
  }

  &.off {
    color: $Color-Neutral-4;
  }
}

.webinar-card {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &.vertical {
    &.title {
      -webkit-line-clamp: 2;
    }

    &.content-description {
      -webkit-line-clamp: 4;
    }

    &.subtitle {
      -webkit-line-clamp: 1;
    }
  }

  &.horizontal {
    &.title {
      -webkit-line-clamp: 1;
    }

    &.content-description {
      -webkit-line-clamp: 2;
    }

    &.subtitle {
      -webkit-line-clamp: 1;
    }
  }
}

.catalogue-card {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &.vertical {
    &.title {
      -webkit-line-clamp: 2;
    }

    &.content-description {
      -webkit-line-clamp: 4;
    }
  }

  &.horizontal {
    &.title {
      -webkit-line-clamp: 1;
    }

    &.content-description {
      -webkit-line-clamp: 2;
    }
  }
}

.cours-card {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &.vertical {
    &.title {
      -webkit-line-clamp: 2;
    }
  }

  &.horizontal {
    &.title {
      -webkit-line-clamp: 1;
    }
  }
}

.chapter-preview-card {
  padding: 24px 24px 40px 24px;
  max-width: 180px;
  height: 264px;
}

.title-chapter-preview-card {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 132px;
  height: 112px;
}

.product-detail-payment {
  background-color: $Color-White;
  border-radius: 3px;
  box-shadow: 0px 8px 12px rgba(195, 205, 206, 0.25);
  padding: 24px;
  width: 382px;
}

.product-detail-payment-img {
  height: 192px;
  object-fit: cover;
  border-radius: 3px 3px 0px 0px;
}

.hr-payment {
  color: $Color-Neutral-4;
  opacity: 1;
}

.grid-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 24px 24px;
  box-sizing: border-box;
  align-content: stretch;
  padding: 0 0px 24px;
  width: 100%;
}

.formation-card {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &.horizontal {
    &.title {
      -webkit-line-clamp: 1;
    }
  }
}

.training-card-timing {
  min-width: 87px;
}

.training-card-lvl {
  width: 211px;

  &.hovered {
    width: 232px;
  }
}


@media (max-width: 1020px) {
  .detail-liste-responsive {
    flex-direction: column !important;
    align-items: start !important;
  }

  .detail-liste-responsive-actions {
    margin-top: 16px;
    justify-content: start !important;

    &.progress-path {
      min-width: auto !important;
    }
  }

  .detail-liste-responsive-session {
    flex-wrap: wrap;
  }

  .detail-liste-responsive-session-inputs-date {
    margin-top: 0px !important;
    width: 40%;
  }

  .detail-liste-responsive-session-inputs-select {
    width: 40%;
    margin-right: 16px;
  }

  .detail-liste-responsive-session-inputs {
    width: 100% !important;
    flex-direction: row !important;
    margin-right: 0px !important;
  }

  .formation-card {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;

    &.horizontal {
      &.title {
        -webkit-line-clamp: 2 !important;
      }
    }
  }

  .detail-liste-responsive-other {
    &.session {
      margin-top: 16px;
      margin-left: 0px !important;
    }
  }
}

@media (max-width: 650px) {
  .title {
    height: 64px !important;
  }

  .detail-liste-responsive-session-inputs {
    width: 100% !important;
    flex-direction: column !important;
  }

  .detail-liste-responsive-session-inputs-date {
    width: 100%;
  }

  .detail-liste-responsive-session-inputs-select {
    width: 100%;
    margin-right: 0px !important;
    margin-bottom: 8px;
  }

  .detail-liste-responsive-actions {
    flex-wrap: wrap;
    min-width: 100% !important;
  }

  .detail-liste-responsive-select {
    min-width: 100% !important;
  }

  .detail-liste-responsive-other {
    margin-top: 16px;
    margin-left: 0px !important;
    padding-left: 0px !important;

    &.progress-training {
      margin-top: 0px !important;
    }
  }

  .detail-liste-responsive-chapter {
    margin-top: 8px;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .training-card-timing {
    justify-content: start !important;
    padding-top: 12px;
  }
}

@media (max-width: 380px) {
  .detail-liste-responsive-level {
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 8px !important;
  }
}

.card-group {
  position: relative;
  max-width: 384px;
  padding-right: 24px;

  &.modifiers {
    position: absolute;
    right: -16px;
    top: 12px;
  }

  &.title {
    max-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;

    &.hovred {
      -webkit-line-clamp: unset !important;
      max-height: none !important;
      max-width: 288px !important;
    }
  }
}

.progress-percent-card {
  min-height: 160px;
  width: 468px;
}

.progress-bar-bg {
  background: $Color-Neutral-4;
  height: 8px;
  //max-width: 337px;
  border-radius: 4px;

  &.success {
    width: 25%;
    background-color: $Color-Green-800;
  }

  &.limitation {
    height: 6px;
    background: $Color-Neutral-4;

    &.success {
      width: 25%;
      background-color: $Color-Green-800;
    }

    &.danger {
      width: 25%;
      background-color: $Color-Orange-800;
    }
  }
}

.in-progress-card {
  max-width: 708px;
  height: 160px;
}

.in-progress-card-content {
  position: relative;
}

.in-progress-card-img {
  object-fit: cover;
  width: 75px;
  height: 56px;
  border-radius: 3px;
}

.in-progress-card-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.subliste-cours-ligne {
  height: 2px;
  width: 44px;
  background-color: $Color-Neutral-5;
}

.subliste-all-ligne {
  width: 2px;
  background-color: $Color-Neutral-5;
  position: absolute;
  top: 118px;
  left: 54px;
}

.subliste-all-ligne-follow-course {
  width: 0px;
  background-color: $Color-Neutral-5;
  position: absolute;
  top: 118px;
  left: 54px;
}

.offer-card {
  width: 384px;
  border-radius: 8px;
  padding: 40px 24px;
}


.payment-succeded {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 40px;
  width: 381px;
  height: 396px;
  background: #FFFFFF;
  box-shadow: 0px 12px 16px rgba(195, 205, 206, 0.08), 0px 4px 6px rgba(195, 205, 206, 0.03);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.payment-succeded-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 190px;
  align-items: center;
  background: #FBFBFD;

}

.dim-student-card {
  cursor: 'pointer';
  &.vertical {
    min-height: 510px;
  }
  &.horizental {
    max-height: 246px;
  }
}

.style-student-image-horizental {
  
  max-height: 246px;
}

