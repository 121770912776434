@import "../Base/variables";

.skeleton {
  transform: none !important;
  background-color: $Color-Neutral-5 !important;
  border-radius: 3px;
  &.text {
    height: 24px;
  }

  &.white {
    background-color: $Color-White !important;
  }
  &.subtext {
    height: 16px;
  }
  &.paragraph {
    height: 64px;
  }
  &.image {
    border-radius: 3px 3px 0 0;
  }
  &.image-horiz {
    width: 360px;
    border-radius: 3px 0 0 3px;
  }
  &.data-table,
  &.data-post {
    height: 16px;
  }
  &.radius {
    border-radius: 24px;
  }
}
