@import "./variables";

@mixin font-face($name, $filename, $weight: 400, $style: normal, $path: "fonts") {
  @font-face {
    font-family: $name;
    src: url("#{$path}/#{$filename}.ttf") format("truetype");
    font-weight: $weight;
    font-style: $style;
  }
}
$fontPath: "../../Fonts/";

@include font-face("Public", "PublicSans-Regular", 400, normal, $fontPath);
@include font-face("Public", "PublicSans-Medium", 500, normal, $fontPath);
@include font-face("Public", "PublicSans-Bold", 700, normal, $fontPath);
@include font-face("Noir", "noir-std-medium", 400, normal, $fontPath);
@include font-face("Noir", "noir-std-regular", 200, normal, $fontPath);
@include font-face("Charter", "Charter-Italic", 400, normal, $fontPath);
@include font-face("Charter", "Charter-Regular", 400, normal, $fontPath);
@include font-face("Inter", "Inter-Regular", 400, normal, $fontPath);
@include font-face("Inter", "Inter-Medium", 500, normal, $fontPath);

$FONT_FAMILY_PUBLIC: "Public", sans-serif;
$FONT_FAMILY_INTER: "Inter", sans-serif;
$FONT_FAMILY_CHARTER: "Charter", sans-serif;
$FONT_FAMILY_NOIR: "Noir", sans-serif;

/** ************* Headlines ***************/

.H1-Headline {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 400;
  font-size: 54px;
  line-height: 64px;
  color: $Color-Black-800;
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.indexes {
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 400!important;
  font-size: 14px;
  line-height: 20px;
  color: $Color-Neutral-2;
}


.expandText {
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 700!important;
  font-size: 14px;
  line-height: 20px;
  color: $Color-Neutral-3;
  text-transform: uppercase;
} 

.H2-Headline {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  color: $Color-Black-800;
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.H3-Headline {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $Color-Black-800;
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.H3-HeadlineOffer {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 200;
  font-size: 24px;
  line-height: 32px;
  color: $Color-Black-800;
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.H3-HeadlineOffer2 {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 200;
  font-size: 32px;
  line-height: 48px;
  color: $Color-Black-800;
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}
.H3-HeadlineTransparent{
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

.H4-Subtitle {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $Color-Black-800;
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.priceText {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $Color-Black-800;
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.H5-Subtitle {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  color: $Color-Neutral-7;
}

.payment-title-mobile{
  color: #272727;
  font-size: 16px;
  font-weight:200;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  font-family: $FONT_FAMILY_NOIR;
}

.payment-subtitle-mobile{
  color: #5A5A5A;
  font-size: 12px;
  font-weight:100;
  line-height: 15px;
  letter-spacing: 0.15px;
  font-family: $FONT_FAMILY_NOIR;
}

.payment-price-description-mobile{
  color: #272727;
  font-size: 14px;
  font-weight:300;
  letter-spacing:0.15px;
  font-family: $FONT_FAMILY_NOIR;
}

.payment-price-mobile{
  color: #272727;
  font-size: 14px;
  font-weight:400;
  letter-spacing: 0em;
  font-family: $FONT_FAMILY_NOIR;
}

.payment-montant-mobile{
  color: #272727;
  font-size: 15px;
  font-weight:400;
  letter-spacing: 0em;
  font-family: $FONT_FAMILY_NOIR;
}

/** ************* Body ***************/

.body-xl {
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  &.bold {
    font-weight: 700;
  }
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.body-md {
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  &.bold {
    font-weight: 700;
  }
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.black-800 {
    color: $Color-Black-800;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.body-error {
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 400;
  font-size: 14px;
  &.bold {
    font-weight: 700;
  }
}


.body-sm-noir {
  font-family: $FONT_FAMILY_NOIR;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: $Color-Neutral-3;
}

.user-nabvar {
  margin-right: 12px;
}

.user-navbar-name {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: $Color-Black-800;
}

.body-sm {
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  &.bold {
    font-weight: 700;
  }
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

.body-cours {
  font-family: $FONT_FAMILY_CHARTER;
  line-height: 32px;
  font-size: 20px;
  &.citation {
    font-style: italic;
  }
}

.sigles {
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
}

/** ************* Buttons ***************/

.Button {
  &-xl {
    font-family: $FONT_FAMILY_PUBLIC;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
  }

  &-md {
    font-family: $FONT_FAMILY_PUBLIC;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
  }

  &-sm {
    font-family: $FONT_FAMILY_PUBLIC;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
  }

  &-text {
    font-family: $FONT_FAMILY_PUBLIC;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    &-sm {
      font-family: $FONT_FAMILY_PUBLIC;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
    }
  }
}

.form {
  &-title {
    font-family: $FONT_FAMILY_PUBLIC;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $Color-Black-800;
  }

  &-default {
    font-family: $FONT_FAMILY_PUBLIC;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $Color-Neutral-3;
  }

  &-edit {
    font-family: $FONT_FAMILY_PUBLIC;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $Color-Black-800;
  }
  &-error {
    font-family: $FONT_FAMILY_PUBLIC;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $Color-Black-800;
  }
}

.form-title-welcome {
  font-family: $FONT_FAMILY_INTER;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $Color-Black-800;
  margin-bottom: 10px;
}
.form-default-welcome {
  font-family: $FONT_FAMILY_INTER;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $Color-Neutral-3;
}
.error-input-welcome {
  font-family: $FONT_FAMILY_INTER;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $Color-Red-800;
}
.input-welcome-tag {
  font-family: $FONT_FAMILY_INTER;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $Color-Black-800;
}

.offer-price {
  font-family: $FONT_FAMILY_NOIR;
  font-size: 36px;
  line-height: 48px;
  color: $Color-Black-800;
  &.small {
    font-size: 16px;
    line-height: 32px;
    color: $Color-Neutral-2;
  }
  &.delete {
    text-decoration-line: line-through;
    font-size: 36px;
    line-height: 48px;
    color: $Color-Neutral-2;
  }
}

.offer-price2 {
  font-family: $FONT_FAMILY_NOIR;
  font-size: 54px;
  line-height: 64px;
  flex: none;
order: 0;
flex-grow: 0;
  color: $Color-Black-800;
  
}
.small2 {
  text-decoration-line: line-through;
  font-size: 36px;
  line-height: 48px;
  flex: none;

  margin-left: 8px;
  order: 1;
  flex-grow: 0;
  color: $Color-Neutral-2;
}

.H3-Headline-path-course-title {
  font-family: $FONT_FAMILY_NOIR;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $Color-Black-800;
  &.neutral-3 {
    color: $Color-Neutral-3;
  }
  &.neutral-2 {
    color: $Color-Neutral-2;
  }
}

@media only screen and (max-width: 576px) {
  .H3-Headline-path-course-title{
    font-size: 18px;
    line-height: 28px;
  }
}