
.upload-image-cover {
  max-width: 1440px;
  height: 360px;
  background-color: $Color-Black-800;
}

.setting-size-cover{
  cursor: pointer;
  color: $Color-Black-800;
  font-size: 16px;
}

.bg-setting-size-cover {
  background-color: $Color-White;
  padding: 9px 24px;
  border-radius: 3px;
}

.setting-size-cover-stat {
  background: rgba(255, 255, 255, 0.7);
  padding: 12px 14px 12px 16px ;
  display: flex;
  flex-direction: row;
  align-items: center;
}
