@import "./sass/Base/typography";
@import "./sass/Base/predefined_class";
@import "./sass/Components/buttons";
@import "./sass/Components/email-notif";
@import "./sass/Components/states";
@import "./sass/Layout/navbar";
@import "./sass/Layout/notification";
@import "./sass/Layout/cards";
@import "./sass/Layout/error-pages";
@import "./sass/Components/activitiesCard";
@import "./sass/Components/accordion";
@import "./sass/Components/files";
@import "./sass/Components/inputs";
@import "./sass/Components/icons";
@import "./sass/Components/dotsAnimation";
@import "./sass/Layout/footer";
@import "./sass/Layout/table";
@import "./sass/Layout//admission-sidebar";
@import "./sass/Layout/background";
@import "./sass/Layout/progressChapter";
@import "./sass/Base/skeleton";
@import "./sass/Base/loading";
@import "./sass/Base/colors";
@import "./sass/Components/table-all-team-component";
@import "./sass/Components/school-preview-component";
@import "./sass/Components/user-guide";
@import "./sass/Components/sondage";

.top-bar-notification .w-25 {
  width: 25%;
}

.success-icon {
  margin: 0 auto;

  svg {
    width: 40px;
    height: 30px;
    fill: #9DDBA9
  }
}

// #my-first-step {}

.container-progress-bar {
  margin: 20px auto 15px auto;
  background-color: #CACBCE;
  width: 400px;
}

.neutral-3-icon {
  color: $Color-Neutral-3;
}

.border-dashed {
  border: 3px dashed #ddd;
}

body {
  background-color: $Color_Neutral_7;
}

.page-wrapper-height {
  min-height: calc(100vh - 80px);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #cacbce;
  border-radius: 10px;
}

.relative-position {
  position: relative;
}



.underline-text {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mt-20 {
  margin-top: 20%;
}

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-0 {
  margin-bottom: 0;
}

.delete-btn {
  width: 50px;
  height: 50px;
  background-color: #F2F2F5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}

.add-btn {
  width: 50px;
  height: 50px;
  background-color: #F2F2F5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.drag-drop-subtitle {
  background-color: #FBFBFD;
  border: 1px dashed #DADBDF;
  color: #CACBCE;
}

.select-file {
  background-color: #DADBDF;
  padding: 2px 10px;
  border-radius: 2px;
}

.label-input-file {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.border-isdroped {
  border: 3px dashed #000;
}

.msg-error-upload-subtitle {
  color: #E06A6A;
  bottom: -25px;
  font-family: 'Public';
}



///////
.MuiListSubheader-root {
  position: unset !important;
}

.MuiMenu-paper {
  max-height: 350px !important;
}

.display-header {
  visibility: hidden;
}

.finance-height-table {
  min-height: calc(100vh - 482px) !important;
}

.player-wrapper {
  position: relative;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.msg-formation-list-empty {
  text-align: center;
  font-weight: 500;
}


// .position-status-training {
//    display: flex;
//    justify-content: end;
// }

.vertical-line {
  border-left: 2px solid #F2F2F5;
  display: inline-block;
  height: 35px;
  margin: 0 9px;
}

.content-status-training {
  margin-right: 45px;
}

.position-cursor-training {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 45px;
}

.msg-error {
  font-size: 16px;
  color: #E06A6A;
  position: absolute;
  bottom: -25px;
}

.vl {
  border-left: 1px solid #F2F2F5;

}

.MuiLinearProgress-colorPrimary {
  background-color: #CACBCE !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #272727 !important;
}

.container-video-loading {
  position: relative;


  .color-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      color: #fff;
      width: 131px !important;
      height: 130px !important;
    }
  }
}

.mt-40 {
  margin-top: 40px;
}

.custom-video-youtube {
  div {
    div {
      iframe {
        height: 100% !important;
      }
    }
  }
}

.box-shadow-navbar-bredcrumb {
  box-shadow: 0px 5px 7px -1px rgba(0, 0, 0, 0.74);
  z-index: 9;
}


.forgot-password {
  background-color: $Color-Neutral-7;
  border: 0;
  border-bottom: 0.5px solid;
  cursor: pointer;
}

.forgot-password:hover {
  color: $Color-Neutral-2;
  border-bottom: 0.5px solid $Color-Neutral-2;
}

.max-width {
  max-width: 1200px;
}

.padding-description {
  padding: 54px 103px
}

.width {
  width: 60%;
}

.max-width-how-it-works {
  max-width: 384
}

.rotate-how-it-works {
  transform: rotate(0)
}

.course-img-padding-top {
  padding-top: 40px
}

@media only screen and (max-width: 1200px) {
  .max-width {
    max-width: 600px;
  }

  .padding-description {
    padding: 20px 0px
  }

  .width {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .max-width-how-it-works {
    width: 100%
  }

  .rotate-how-it-works {
    transform: rotate(90deg)
  }

  .course-img-padding-top {
    padding-top: 0px
  }
}

@media only screen and (max-width: 768px) {
  .cours-nav {
    background: white;
  }

  .cours-nav .course-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    color: #272727;
    font-size: 14px;
    margin-top: 20px;
    font-family: "NoirStd-Regular sans-serif";
    text-align: center;
  }

  .cours-nav .sub-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    color: #5A5A5A;
    font-size: 11px;
    font-family: "NoirStd-Regular sans-serif";
    text-align: center;
  }

  .nav-btn-chapter {
    font-family: Public Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: #272727;
  }

  .nav-btn-chapter-active {
    font-family: Public Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: #CACBCE;
  }

  .border-btn-chapter {
    border: 1px solid #F2F2F5;
  }
}

@media only screen and (max-width: 1200px) {

  .training-price{
    color: #CACBCE;
  }
}