:root {
    --custom-totalTopSpotlight: 20px;
    --custom-totalTopMenuParam: 12px;
    --custom-totalTopStep7: -190px;
    --user-guide-is-run: -1;
}

#initial-step-user-guide {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-user-guide {
        width: 540px;
        height: 320px;
        background-color: #fff;
        border-radius: 3px;
        padding: 35px 45px 25px 45px;
        font-family: 'Public';

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            margin: 0;
        }

        .header-modal-user-guide {}

        .body-modal-user-guide {}

        .footer-modal-user-guide {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-no-thanks {
                color: $Color-Neutral-2
            }

            .pagination {
                color: $Color-Neutral-2
            }
        }
    }

    .modal-user-guide--update {
        width: 540px;
        height: 260px;
        background-color: #fff;
        border-radius: 3px;
        padding: 35px 45px 17px 45px;
        font-family: 'Public';

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            margin: 0;
        }

        .header-modal-user-guide {
            margin-bottom: 20px;
        }

        .body-modal-user-guide {}

        .footer-modal-user-guide {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-no-thanks {
                color: $Color-Neutral-2
            }

            .pagination {
                color: $Color-Neutral-2
            }
        }
    }
}

.react-joyride__tooltip>div:first-child {
    border-bottom: 1px solid #ddd;
}

button[aria-label="Back"] {
    display: none;
}

button[aria-label="Next"] {
    text-indent: -9000px;
    width: 100px;
    height: 35px;
    @include display-flex(center, center);
    background-color: #000 !important;
    font-family: 'Public';
    font-weight: 700;
}


button[aria-label="Next"]::after {
    content: "SUIVANT";
    text-indent: 0;
    display: flex;
    font-size: 14px;
}

button[aria-label="Last"] {
    text-indent: -9000px;
    width: 100px;
    height: 35px;
    @include display-flex(center, center);
    background-color: #000 !important;
    z-index: 999;
}

button[aria-label="Last"]::after {
    content: "COMMENCER";
    text-indent: 0;
    display: flex;
    font-size: 13px;
    font-family: "Public" !important;
    font-weight: 700;

    
}

button[aria-label="Passer"]::after {
    color: #5A5A5A !important;
}


.react-joyride__spotlight--update-step-7 {
    top: var(--custom-totalTopMenuParam) !important;
    height: 530px !important;
}

// 0 2 4 8 10 12 steps:navbar

$j: 14;
@while $j >= 0 {
    #react-joyride-step-#{$j} {
        width: 100%;
        height: 100vh;
        position: absolute;
    }
    $j: $j - 1;
}

$i: 12;

@while $i >=0 {
    .react-joyride__spotlight--update-step-#{$i} {
        border-radius: 50px !important;
        height: 35px !important;
        top: var(--custom-totalTopSpotlight) !important;

        @if($i ==6) {
            top: 15px !important;
        }

        @if($i ==7) {
            top: var(--custom-totalTopMenuParam) !important;
            height: 483px !important;
            width: 240px !important;
        }
    }

    
    
    @if ($i ==8) {
        $i: $i - 4;
    }

    @else {
        $i: $i - 2;
    }
}

// 1 3 5 9 11 13 14 steps:content
.react-joyride__spotlight--update-step-1 {
    display: none !important;
}

.react-joyride__spotlight--update-step-3 {
    display: none !important;
}

.react-joyride__spotlight--update-step-5 {
    display: none !important;
}

.react-joyride__spotlight--update-step-9 {
    display: none !important;
}

.react-joyride__spotlight--update-step-11 {
    display: none !important;
}

.react-joyride__spotlight--update-step-13 {
    display: none !important;
}

.react-joyride__spotlight--update-step-14 {
    display: none !important;
}

#react-joyride-step-0{
    .__floater__open{
        padding: 16px 90px 0px !important;
    }
}

#react-joyride-step-1 {
    .__floater__open--update-true {
        padding: 0 !important;
        left: 35% !important;
        transform: none !important;
        right: 35% !important;
        top: 80px !important;
    }

    .__floater__open--update-false {
        padding: 0 !important;
        left: 35% !important;
        transform: none !important;
        right: 35% !important;
        top: 147px !important;
    }
}

#react-joyride-step-2{
    .__floater__open{
        padding: 16px 82px 0px !important;
    }
}

#react-joyride-step-3 {
    .__floater__open--update-true {
        padding: 0 !important;
        top: 20% !important;
        right: 10% !important;
        left: 65% !important;
        width: 380px !important;
    }

    .__floater__open--update-false {
        padding: 0 !important;
        top: 28% !important;
        right: 10% !important;
        left: 65% !important;
    }
}

#react-joyride-step-4{
    .__floater__open{
        padding: 16px 24px 0px !important;
    }
}

#react-joyride-step-5 {
    .__floater__open--update-true {
        padding: 0 !important;
        top: 0 !important;
        right: 10% !important;
        left: 70% !important;
        width: 380px !important;
    }

    .__floater__open--update-false {
        padding: 0 !important;
        top: 0 !important;
        right: 10% !important;
        left: 70% !important;
        width: 380px !important;
    }
}

#react-joyride-step-6 {
    .__floater__open--update-true {
        top: 5px !important;
    }

    .__floater__open--update-false {
        top: 0px !important;
    }
}

#react-joyride-step-7 {
    .__floater__open{
        margin-top:116px;
        padding:138px 16px 0px 0px !important;
    }
    .__floater__open--update-true {
        top: var(--custom-totalTopStep7) !important;
    }

    .__floater__open--update-false {
            top: var(--custom-totalTopStep7) !important;
    }
}

#react-joyride-step-8{
    .__floater__open{
        padding: 16px 92px 0px !important;
    }
}

#react-joyride-step-9 {
    .__floater__open--update-true {
        padding: 0 !important;
        left: 43% !important;
        transform: translate3d(-40px, 85px, 0px) !important;

    }
}

#react-joyride-step-10{
    .__floater__open{
        padding: 16px 116px 0px !important;
    }
}

#react-joyride-step-11 {
    .__floater__open--update-true {
        padding: 0 !important;
        transform: translate3d(-36px, 85px, 0px) !important;
        right: 0 !important;
        left: 67% !important;
    }
}

#react-joyride-step-12{
    .__floater__open{
        padding: 16px 80px 0px !important;
    }
}

#react-joyride-step-13 {
    .__floater__open--update-true {
        padding: 0 !important;
        transform: translate3d(-48px, 93px, 0px) !important;
        right: 0 !important;
        left: 68% !important;
    }
}

#react-joyride-step-14 {
    .__floater__open--update-true {
        padding: 0 !important;
        top: 20% !important;
        left: 40% !important;

        .__floater__body {
            .react-joyride__tooltip {
                width: 500px !important;
            }
        }
    }
}


.text-align {
    @include text-align(left)
}

.count-of-steps {
    position: absolute;
    bottom: 22px;
    color: #5A5A5A;
    left: 135px;
}

.count-of-steps--center {
    width: 100%;
    left: 0;
    text-align: center;
}

.root-check-user-guide {
    z-index: -1;
    position: absolute;
}


