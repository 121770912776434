@import "../Base/typography";
@import "../Base/variables";
@import "../Base/mixins";

/** ************* Nav-Buttons ***************/

.nav {
  user-select: none;
  cursor: pointer;
  &-back {
    user-select: none;
    cursor: pointer;
    background-color: $Color-White;
    height: 64px;
    min-width: 103px;
    color: $Color-Black-800;
    box-shadow: inset 0px -1px 0px #f5f5f6;

    &:hover {
      color: $Color-Black-800;
      background-color: $Color-Neutral-6;
    }
  }
  &.edition {
    @extend .Button-md;

    user-select: none;
    cursor: pointer;
    background-color: $Color-Blue-800;
    color: $Color-White;
    min-width: 103px;
    height: 64px;

    &:hover {
      color: $Color-White;
      background-color: $Color-Blue-600;
    }
  }

  &.publish {
    background-color: $Color-Green-800;
    color: $Color-White;
    min-width: 103px;
    user-select: none;
    cursor: pointer;

    @extend .Button-md;

    height: 64px;

    &:hover {
      color: $Color-White;
      background-color: $Color-Green-600;
    }

    &.disabled {
      color: $Color-Neutral-3;
      background-color: $Color-Neutral-5;
      cursor: default;
      pointer-events: none;
      text-decoration: none;
      user-select: none;
    }
  }

  &.refuse {
    background-color: $Color-Red-800;
    color: $Color-White;
    height: 64px;
    min-width: 103px;
    user-select: none;
    cursor: pointer;

    @extend .Button-md;

    &:hover {
      color: $Color-White;
      background-color: $Color-Red-600;
    }
  }

  &.secondary-action {
    @extend .Button-md;

    color: $Color-White;
    user-select: none;
    cursor: pointer;

    &:hover {
      color: $Color-Neutral-3;
    }
  }

  &-state-refused {
    background-color: $Color-Red-200;
    color: $Color-Red-800;

    @extend .body-sm;

    font-weight: 700 !important;
    height: 64px;
    min-width: 103px;
    pointer-events: none;
  }

  &-btn-white {
    user-select: none;
    cursor: pointer;
    background-color: $Color-White;
    color: $Color-Neutral-3;
    padding: 4px 16px;
    border-radius: 50px;
    font-weight: 700 !important;

    @extend .body-md;

    &-active {
      user-select: none;
      cursor: pointer;
      background-color: $Color-Black-800;
      color: $Color-White;
      border-radius: 50px;
      padding: 4px 16px;
      font-weight: 700 !important;

      @extend .body-md;

      &:hover {
        background-color: $Color-Black-800;
        color: $Color-White;
      }
    }

    &:hover {
      background: $Color-Neutral-6;
      color: $Color-Neutral-3;
    }
  }

  &-btn-black {
    user-select: none;
    cursor: pointer;
    background-color: $Color-Black-800;
    color: $Color-White;
    border-radius: 50px;
    padding: 4px 16px;
    font-weight: 700 !important;

    @extend .body-md;

    &.icon {
      display: flex;
      padding: 4px 16px;
      border: none;
      align-items: center;
    }
    &-active {
      user-select: none;
      cursor: pointer;
      background-color: $Color-White;
      color: $Color-Black-800;
      border-radius: 50px;
      padding: 4px 16px;
      font-weight: 700 !important;

      @extend .body-md;
    }

    &:hover {
      background: $Color-Neutral-2;
      color: $Color-White;
    }
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}

/** ************* Nav-more-Buttons ***************/
.list-more {
  user-select: none;
  cursor: pointer;
  background-color: $Color-White;
  padding: 2px;
  position: absolute;
  top: 100%;
  width: 172px;
  left: 1px;
  border-radius: 3px;
  box-shadow: 2px 6px 6px rgba(218, 218, 218, 0.25);
  z-index: 2;
}

.buttons-liste {
  @extend .body-md;

  user-select: none;
  cursor: pointer;
  padding: 8px 16px;
  font-weight: 700 !important;
  color: $Color-Black-800;
  background-color: $Color-White;
  width: 100%;
  text-align: left;
  border: none;
  &:hover {
    background-color: $Color-Neutral-5;
    color: $Color-Black-800;
  }
}

/********************  button text ********************/
.btn-text {
  @extend .body-md;

  cursor: pointer;
  user-select: none;
  width: fit-content;
  padding: 0 !important;
  height:28px;
  &.outlined {
    border: none;
    background-color: $Color-White;
    border-bottom: 1px solid $Color-Black-800;
    padding: 0;

    &:hover {
      color: $Color-White;
      background-color: $Color-Black-800;
    }
    &.danger {
      border-bottom: 1px solid $Color-Red-800;
      &:hover {
        color: $Color-White;
        background-color: $Color-Red-800;
      }
    }
  }
  &.small {
    @extend .Button-text-sm;

    font-weight: 500 !important;
    background-color: $Color-White;
    border: none;
    color: $Color-Black-800;
    &:hover {
      color: $Color-Black-1000;
    }
  }
  &.with-icon {
    @extend .body-sm;

    font-weight: 700 !important;
    display: flex;
    align-items: center;
    border: none;
    background-color: $Color-White;
    padding: 0;
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}

/********************  button primary ********************/
.btn-Primary {
  cursor: pointer;
  user-select: none;
  background-color: $Color-Black-800;
  color: $Color-White;
  border: none;
  border-radius: 4px;

  &.large {
    @extend .Button-md;

    padding: 16px;

    &.icon-left {
      display: flex;
      padding: 12px 16px;
      height: min-content;
      width: max-content;
    }

    &.icon-right {
      display: flex;
      padding: 12px 16px;
      height: min-content;
      width: max-content;
    }
  }

  &.medium {
    @extend .Button-md;

    padding: 10px 16px;

    &.icon-left {
      display: flex;
      padding: 6px 12px 6px 8px;
      height: min-content;
      width: max-content;
    }

    &.icon-right {
      display: flex;
      padding: 6px 8px 6px 12px;
      height: min-content;
      width: max-content;
    }
  }

  &.small {
    padding: 6px 16px;

    @extend .Button-sm;

    &.icon-left {
      display: flex;
      padding: 2px 8px 2px 4px;
      height: min-content;
      width: max-content;
    }

    &.icon-right {
      display: flex;
      padding: 2px 4px 2px 8px;
      height: min-content;
      width: max-content;
    }
  }

  &:hover {
    background-color: $Color-Black-600;
    color: $Color-Neutral-4;
  }

  &:disabled {
    background-color: $Color-Neutral-5;
    color: $Color-Neutral-3 !important;
    opacity: 1;
  }

  &.icon {
    display: flex;
    padding: 12px 16px;

    &.start-webi {
      background-color: $Color-Purple-800;
    }

    &.valide-webi {
      background-color: $Color-Green-800;
    }

    &.refuse-webi {
      background-color: $Color-Red-800;
    }

    &.enter-webi {
      background-color: $Color-Blue-800;
    }

    &.agenda-webi {
      background-color: $Color-Green-200;
      color: $Color-Green-800;
    }
  }

  &.loaddata {
    background-color: $Color-Black-800;
    &.large {
      height: 48px;
      width: 142px;
    }
    &.medium {
      height: 36px;
      width: 128px;
    }
    &.small {
      height: 28px;
      width: 115px;
    }
  }

  &.load {
    background-color: $Color-Black-1000;
    cursor: default;
    padding: 22px 16px;

    &.load .progress {
      width: 110px;
      height: 4px;
      background-color: $Color-White;
    }

    &.load .progress .progress-bar {
      background-color: $Color-Green-800;
    }
  }

  &.success-loading {
    background-color: $Color-Black-1000;
    cursor: default;
    color: $Color-Green-800;
    width: 142px;
    padding-top: 11px;
    padding-bottom: 10px;
  }

  &.danger {
    background-color: $Color-Red-800;
    &:hover {
      background-color: $Color-Red-600;
      color: $Color-White;
    }
    &:disabled {
      background-color: $Color-Neutral-5;
      color: $Color-Neutral-3 !important;
      opacity: 1;
    }
  }

  &.success {
    background-color: $Color-Green-800;
    &:hover {
      background-color: $Color-Green-600;
      color: $Color-White;
    }
    &:disabled {
      background-color: $Color-Neutral-5;
      color: $Color-Neutral-3 !important;
      opacity: 1;
    }
  }

  &.adding-done {
    background-color: $Color-Green-200;
    color: $Color-Green-800;
  }

  &.info {
    background-color: $Color-Purple-800;
    &:hover {
      background-color: $Color-Purple-600;
      color: $Color-White;
    }
    &:disabled {
      background-color: $Color-Neutral-5;
      color: $Color-Neutral-3 !important;
      opacity: 1;
    }
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}

/********************  button secondary ********************/
.btn-Secondary {
  cursor: pointer;
  user-select: none;
  background-color: $Color-Neutral-5;
  border-radius: 4px;
  color: $Color-Black-800;
  border: none;

  &.large {
    @extend .Button-md;

    padding: 16px;

    &.icon-left {
      display: flex;
      padding: 12px 16px;
      height: min-content;
      width: max-content;
    }

    &.icon-right {
      display: flex;
      padding: 12px 16px;
      height: min-content;
      width: max-content;
    }
  }

  &.medium {
    @extend .Button-md;

    padding: 10px 16px;

    &.icon-left {
      display: flex;
      padding: 6px 12px 6px 8px;
      height: min-content;
      width: max-content;
    }

    &.icon-right {
      display: flex;
      padding: 6px 8px 6px 12px;
      height: min-content;
      width: max-content;
    }
  }

  &.small {
    padding: 5px 16px;

    @extend .Button-sm;

    &.icon-left {
      display: flex;
      padding: 2px 8px 2px 4px;
      height: min-content;
      width: max-content;
    }

    &.icon-right {
      display: flex;
      padding: 2px 4px 2px 8px;
      height: min-content;
      width: max-content;
    }
  }

  &:hover {
    background-color: $Color-Neutral-4;
  }

  &:disabled {
    background-color: $Color-Neutral-5;
    color: $Color-Neutral-3;
    opacity: 1;
  }

  &.loaddata {
    background-color: $Color-Neutral-5;
    &.large {
      height: 48px;
      width: 142px;
    }
    &.medium {
      height: 36px;
      width: 128px;
    }
    &.small {
      height: 28px;
      width: 115px;
    }
  }

  &.load {
    padding: 22px 16px;

    &.load .progress {
      width: 174px;
      height: 4px;
      background-color: $Color-Green-400;
    }

    &.load .progress .progress-bar {
      background-color: $Color-Green-800;
    }
  }
  &.adding-done {
    background-color: $Color-Green-200;
    color: $Color-Green-800;
    width: 116px !important;
    &:hover {
      background-color: $Color-Green-400;
    }
  }
  &.success {
    background-color: $Color-Neutral-5;
    cursor: default;
    color: $Color-Green-800;
    width: 142px;
    padding-top: 11px;
    padding-bottom: 10px;
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}

/********************  button outlined ********************/
.btn-outlined {
  @extend .Button-xl;

  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  padding: 18px 16px;
  background: $Color-White;
  color: $Color-Black-800;
  border: 2px solid $Color-Neutral-5;

  &.medium {
    padding: 10px 16px;
  }

  &.selected {
    border-color: $Color-Black-800;

    &:hover {
      background-color: $Color-White;
    }
  }

  &:hover {
    border-color: $Color-Black-800;
    color: $Color-Black-600;
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}

/********************  Quick actions ********************/
.btn-QuickActions {
  cursor: pointer;
  user-select: none;
  background-color: $Color-Neutral-5;
  border: none;
  border-radius: 3px;
  color: $Color-Black-800;
  padding: 11px;
  height: 48px;
  width: 48px;
  &.transparent {
    background-color: $Color-White;
    &:hover {
      background-color: $Color-Neutral-5;
    }
    &.bggrey {
      background-color: $Color-Neutral-5;
    }

  }
  &.chat {
    color: $Color-Green-800;
    position: relative;
  }
  &.white {
    background-color: transparent;
    border: solid 1px $Color-White;
    color: $Color-Neutral-7;
    box-sizing: border-box;

    &:disabled {
      background-color: transparent;
      border: solid 1px $Color-White;
      color: $Color-Neutral-7;
      box-sizing: border-box;
    }

    &:hover {
      background-color: $Color-White;
      border-color: $Color-Black-800;
      color: $Color-Black-600;
    }
  }
  &.outlined {
    background-color: transparent;

    &.pdf {
      padding: 7px 12px 4px 12px;
      display: flex;
      flex-direction: column;
      font-family: $FONT_FAMILY_NOIR;
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
    }

    &:disabled {
      background-color: transparent;
      color: $Color-Neutral-3;
      opacity: 1;
      &:hover {
        background-color: $Color-White;
        color: $Color-Neutral-3;
        border-color: $Color-Neutral-5;
      }
    }

    &:hover {
      background-color: $Color-White;
      border-color: $Color-Black-800;
      color: $Color-Black-600;
    }
  }

  &:hover {
    background-color: $Color-Neutral-4;
  }

  &:disabled {
    background-color: $Color-Neutral-5;
    color: $Color-Neutral-3;
    opacity: 1;
    cursor: default;
  }
  &.medium {
    width: 36px !important;
    height: 36px !important;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 18px !important;
    }
  }
  &.small {
    width: 32px !important;
    height: 32px !important;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 16px !important;
    }
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}
.numbernotif {
  position: absolute;
  left: 19px;
}

/********************  button tabs  ********************/
.btn-Tabs {
  @extend .body-md;

  cursor: pointer;
  user-select: none;
  font-weight: 700 !important;
  background-color: $Color-Black-800;
  color: $Color-White;
  padding: 4px 16px;
  border-radius: 24px;
  border: none;

  &:hover {
    background-color: $Color-Black-1000;
    color: $Color-White;
  }

  &:disabled {
    background-color: $Color-White;
    color: $Color-Neutral-3;
    border: 2px solid $Color-Neutral-4;
    padding: 2px 16px;
    opacity: 1;
  }

  &.unselected {
    background-color: $Color-Neutral-5;
    color: $Color-Neutral-3;

    &:hover {
      background-color: $Color-Neutral-6;
      color: $Color-Neutral-4;
    }
  }
  &.outlined {
    @extend .body-sm;

    padding: 6px 10px;
    border: 2px solid;
    box-sizing: border-box;

    &.white {
      background-color: $Color-White;
      color: $Color-Black-800;
      border-color: $Color-Black-800;
    }
    &.black {
      background-color: $Color-Black-800;
      color: $Color-White;
      border-color: $Color-White;
    }

    &.icon {
      display: flex;
      padding: 4px 10px;
      align-items: center;
    }
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}
.btn-Tabs-uncolored {
  @extend .body-md;
  cursor: pointer;
  user-select: none;
  font-weight: 700 !important;
  background-color: transparent;
  padding: 4px 16px;
  border-radius: 24px;
  border: none;
  &:hover {
    background-color: transparent;
  }
  &:disabled {
    background-color: transparent;
    border: 2px solid $Color-Neutral-4;
    padding: 2px 16px;
    opacity: 1;
  }
  &.unselected {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  &.danger {
    background-color: red;
    &:hover {
      background-color: red;
    }
  }
  &.outlined {
    @extend .body-sm;
    padding: 6px 10px;
    border: 2px solid;
    box-sizing: border-box;
    &.white {
      background-color: transparent;
    }
    &.black {
      background-color: transparent;
    }
    &.icon {
      display: flex;
      padding: 4px 10px;
      align-items: center;
    }
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}
/********************  button group  ********************/
.btn-Group {
  cursor: pointer;
  user-select: none;
  font-family: $FONT_FAMILY_PUBLIC !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
  line-height: 24px !important;
  background-color: transparent !important;
  color: $Color-Black-800 !important;
  border: 1px solid $Color-Black-800 !important;
  padding: 11px 16px !important;

  &:hover {
    background-color: $Color-Neutral-6 !important;
  }

  &.liste {
    padding: 15px 16px !important;
    font-size: 14px !important;
    line-height: 16px !important;
    border-color: $Color-Neutral-5 !important;
    color: $Color-Neutral-3 !important;
    background-color: transparent !important;

    &.active {
      color: $Color-Black-800 !important;

      &:hover {
        color: $Color-Black-800 !important;
      }
    }

    &:hover {
      color: $Color-Black-800 !important;
    }
  }

  &.refused {
    color: $Color-Red-800 !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  &.accepted {
    color: $Color-Green-800 !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  &.disabled {
    color: $Color-Neutral-3 !important;

    &:hover {
      background-color: transparent !important;
    }
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}

.MuiTouchRipple-root {
  display: none;
}

/********************  TOGGLE  ********************/
@mixin state-text($color: $Color-Neutral-3, $opacity: 1) {
  @extend .body-sm;

  font-weight: 700 !important;
  position: absolute;
  opacity: $opacity;
  color: $color;
}

.input-style[type="checkbox"] {
  display: none;
}

.toggle-container {
  border-radius: 20px;
  height: 36px;
  width: 118px;
  cursor: pointer;
  background: $Color-Neutral-2;
  position: relative;

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }

  *,
  *::before,
  *::after {
    transition: 0.1s all ease-in-out 1ms;
  }
  &.cours-editing {
    width: 137px;
    background-color: $Color-Purple-200;
  }
  &.authorisation {
    width: 135px;
    background-color: $Color-Neutral-6;
  }
}

.label-style {
  height: 28px;
  width: 28px;
  border-radius: 50px;
  background: $Color-White;
  position: absolute;
  top: 4px;
  left: 85px;
  box-shadow: 0 4px 4px rgba(118, 117, 117, 0.25);
  cursor: pointer;
  &.cours-editing {
    left: 105px;
  }
  &.authorisation {
    left: 101px;
  }
  &.disabled {
    opacity: 0.8;
    cursor: default;
  }

  .on {
    @include state-text($Color-White, 0);

    top: calc(50% - 11px);
    left: calc(0% - 74px);
    user-select: none;
    cursor: pointer;
  }

  .off {
    display: block;
    user-select: none;
    cursor: pointer;

    @include state-text($Color-White, 1);

    top: calc(50% - 11px);
    left: calc(0% - 74px);

    &.cours-preview {
      @include state-text($Color-Purple-800, 1);

      left: calc(0% - 93px);
    }
    &.authorisation-off {
      @include state-text($Color-Neutral-3, 1);
      left: -90px;
    }
  }
}

.input-style[type="checkbox"]:checked ~ .toggle-container .label-style {
  left: 4px;
}

.input-style[type="checkbox"]:checked ~ .toggle-container .label-style .on {
  @include state-text($Color-White, 1);

  display: block;
  top: calc(50% - 11px);
  right: calc(50% - 75px);
  left: calc(126% - 0px);
  &.cours-editing {
    @include state-text($Color-Orange-800, 1);
  }
  &.authorisation-on {
    @include state-text($Color-Green-800, 1);
    left: 46px;
  }
}

.input-style[type="checkbox"]:checked ~ .toggle-container .label-style .off {
  @include state-text($Color-White, 0);

  top: calc(50% - 11px);
  left: calc(126% - 0px);
  right: calc(126% - 111px);
  &.cours-preview {
    @include state-text($Color-Purple-800, 0);
    display: none;
  }
  &.authorisation-off {
    @include state-text($Color-Neutral-3, 0);
  }
}

.input-style[type="checkbox"]:checked ~ .toggle-container {
  width: 103px;
  background-color: $Color-Green-800;
  &.cours-editing {
    width: 90px;
    background-color: $Color-Orange-200;
  }
  &.authorisation {
    width: 135px;
    background-color: $Color-Green-200;
  }
}

/********************  CHECKBOX  ********************/
.input-style-checkbox[type="checkbox"] {
  display: none;
}

.input-style-checkbox[type="checkbox"] + label {
  border: 2px solid $Color-Neutral-4;
  border-radius: 3px;
  background-color: $Color-White;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.1s all ease-in-out 1ms;
}

.input-style-checkbox[type="checkbox"]:checked + label {
  position: relative;
  border: 2px solid $Color-Black-800;
  border-radius: 3px;
  background-color: $Color-White;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.1s all ease-in-out 1ms;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left:  50%;
    transform: translate(-50%, -50%);

    background: $Color-Black-800;
    border-radius: 3px;
    cursor: pointer;
    height: 14px;
    width: 14px;
    transition: 0.1s all ease-in-out 1ms;
  }
}

/********************  Radio  ********************/

.input-style-radio[type="radio"] {
  display: none;
}

.input-style-radio[type="radio"] + label {
  border: 2px solid $Color-Neutral-4;
  border-radius: 15px;
  background-color: $Color-White;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.1s all ease-in-out 1ms;
}

.input-style-radio[type="radio"]:checked + label {
  transition: 0.1s all ease-in-out 1ms;
  position: relative;
  border: 2px solid $Color-Black-800;
  border-radius: 15px;
  background-color: $Color-White;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    background: $Color-Black-800;
    border-radius: 15px;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 13px;
    width: 13px;
  }
}

/********************  Radio outlined  ********************/

.input-style-radio-button[type="radio"] {
  display: none;
}

.input-style-radio-button[type="radio"] + label {
  @extend .Button-xl;

  transition: 0.1s all ease-in-out 1ms;
  border-radius: 4px;
  padding: 18px 16px;
  background: $Color-White;
  color: $Color-Black-800;
  border: 2px solid $Color-Neutral-5;
  cursor: pointer;
  user-select: none;
}

.input-style-radio-button[type="radio"]:checked + label {
  @extend .Button-xl;

  transition: 0.1s all ease-in-out 1ms;
  border-radius: 4px;
  padding: 18px 16px;
  background: $Color-White;
  color: $Color-Black-800;
  border-color: $Color-Black-800;
  cursor: pointer;
  user-select: none;
  &:before {
    border-color: $Color-Black-800;
    color: $Color-Black-600;
  }
}

/********************  Radio outlined medium ********************/
.input-style-radio-button-medium[type="radio"] {
  display: none;
}

.input-style-radio-button-medium[type="radio"] + label {
  @extend .Button-xl;

  transition: 0.1s all ease-in-out 1ms;
  border-radius: 4px;
  padding: 10px 16px;
  background: $Color-White;
  color: $Color-Black-800;
  border: 2px solid $Color-Neutral-5;
  cursor: pointer;
  user-select: none;
}

.input-style-radio-button-medium[type="radio"]:checked + label {
  @extend .Button-xl;

  transition: 0.1s all ease-in-out 1ms;
  border-radius: 4px;
  padding: 10px 16px;
  background: $Color-White;
  color: $Color-Black-800;
  border-color: $Color-Black-800;
  cursor: pointer;
  user-select: none;
  &:before {
    border-color: $Color-Black-800;
    color: $Color-Black-600;
  }
}
/********************  Radio outlined response ********************/
.input-style-radio-response[type="radio"] {
  display: none;
}
.input-style-radio-response[type="radio"] + label {
  @extend .Button-xl;
  cursor: default;
  transition: 0.1s all ease-in-out 1ms;
  border-radius: 3px;
  padding: 28px 87px;
  background: $Color-White;
  color: $Color-Black-800;
  border: 1px solid $Color-Neutral-5;
  width: 100%;
  box-sizing: border-box;
}

.input-style-radio-response[type="radio"]:checked + label {
  @extend .Button-xl;

  transition: 0.1s all ease-in-out 1ms;
  border-radius: 3px;
  padding: 28px 87px;
  background: $Color-White;
  color: $Color-Black-800;
  border: 2px solid $Color-Black-800;
  box-sizing: border-box;
  cursor: default;
  width: 100%;
  font-weight: bold;
  &:before {
    border-color: $Color-Black-800;
    color: $Color-Black-600;
  }
}

/********************  checkbox outlined response ********************/
.input-style-checkbox-response[type="checkbox"] {
  display: none;
}
.input-style-checkbox-response[type="checkbox"]:disabled + label {
  cursor: default;
  cursor: pointer;
  user-select: none;
}
.input-style-checkbox-response[type="checkbox"] + label {
  @extend .Button-xl;
  cursor: default;
  transition: 0.1s all ease-in-out 1ms;
  border-radius: 3px;
  padding: 28px 87px;
  background: $Color-White;
  box-sizing: border-box;
  user-select: none;
}
.input-style-checkbox-response[type="checkbox"]:checked + label {
  @extend .Button-xl;

  transition: 0.1s all ease-in-out 1ms;
  border-radius: 3px;
  padding: 28px 87px;
  background: $Color-White;
  color: $Color-Black-800;
  border: 2px solid $Color-Black-800;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  user-select: none;
  &:before {
    border-color: $Color-Black-800;
    color: $Color-Black-600;
  }
}
.check-checkbox {
  input[type="checkbox"] {
    &:checked:disabled + label {
      @extend .Button-xl;
      border-color: #5693ee;
      cursor: default;
    }
  }
  @extend .Button-xl;

  transition: 0.1s all ease-in-out 1ms;
  border-radius: 3px 3px 0px 0px;
  padding: 18px 16px;
  background: $Color-White;
  color: $Color-Black-800;
  border-color: $Color-Black-800;
  cursor: default;
  width: 100%;
  &:before {
    border-color: $Color-Black-800;
    color: $Color-Black-600;
  }
}

/********************  Document state button ********************/
.btn-Document {
  cursor: pointer;
  user-select: none;
  background-color: $Color-Neutral-6;
  border-radius: 4px;
  color: $Color-Black-800;
  border: none;
  padding: 5px;
  height: 36px;
  width: 36px;
  &:hover {
    color: $Color-Neutral-2;
  }
  &.refuse {
    background-color: $Color-Red-200;
    color: $Color-Red-800;
  }
  &.accepted {
    background-color: $Color-Green-200;
    color: $Color-Green-800;
  }
  &:disabled {
    background-color: $Color-Neutral-7;
    color: $Color-Neutral-3;
  }
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}

/********************  Button trie  ********************/
.button-trie {
  @extend .body-sm;

  text-transform: uppercase;
  font-weight: 700 !important;
  color: $Color-Neutral-3;
  background-color: transparent;
  border: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  &.active {
    color: $Color-Black-800;
  }
}

.trie-arrow {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  user-select: none;

  &.up {
    top: -1px;
    color: $Color-Neutral-3;
    &.active {
      color: $Color-Black-800;
    }
  }

  &.down {
    top: 4px;
    color: $Color-Neutral-3;
    &.active {
      color: $Color-Black-800;
    }
  }
  &.no-action {
    cursor: default !important;
  }
}


.btn-outlined-payment {
  @extend .Button-xl;
  width: 169px;
  border-radius: 4px;
  padding: 10px 16px;
  background: transparent;
  color: $Color-Black-800;
  cursor: pointer;
  user-select: none;
  &.default {
    border: 2px solid $Color-Neutral-5;
  }
  &.selected {
    border: 2px solid $Color-Black-800;
  }
}
.btn-outlined-offer {
  @extend .body-md;
  border-radius: 4px;
  padding: 16px;
  text-align: left;
  background: transparent;
  color: $Color-Black-800;
  cursor: pointer;
  user-select: none;
  &.default {
    border: 2px solid $Color-Neutral-5;
  }
  &.selected {
    border: 2px solid $Color-Black-800;
  }
}



.btn-text-team{
  @extend .body-md;

  cursor: pointer;
  user-select: none;
  width: fit-content;
  padding: 0 !important;
  height:28px;
  &.outlined {
    border: none;
    background-color: $Color-White;
    border-bottom: 1px solid $Color-Black-800;
    padding: 0;

    &.danger {
      border-bottom: 1px solid $Color-Red-800;
      color: $Color-Red-800;
      &:hover {
        color: $Color-White;
        background-color: $Color-Red-800;
      }
    }
  }
  
  transition: background-color .2s cubic-bezier(.25,.01,.25,1) 0s,color .2s cubic-bezier(.25,.01,.25,1) 0s;
}



/********************  TOGGLE TWO BUTTON ********************/

.input-style-button[type="checkbox"] {
  display: none;
}


.toggle-container-button {
  border-radius: 6px;
  width: 234px;
  height: 52px;
  padding: 4px;
  cursor: pointer;
  background: $Color-Neutral-5;
  position: relative;
  

  *,
  *::before,
  *::after {
    transition: 0.1s all ease-in-out 1ms;
  }
}

.label-style-button {
  height: 44px;
  width: auto;
  content: "text";
  border-radius: 4px;
  color: $Color-Black-800;
  background: $Color-White;
  position: absolute;
  


  padding: 10px, 16px, 10px, 16px;
  box-shadow: 0 1px 2px rgba(39, 39, 39, 0.06), 0px 1px 3px rgba(39, 39, 39, 0.1);
  cursor: pointer;
  gap: 8px;

  letter-spacing: 0.15px;

  .on2 {
    margin-top: 1px;
    @include state-text($Color-Neutral-2, 0);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    font-size: 16px!important;
    
  }


  .off2 {
    margin-top: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    font-size: 16px!important;
    @include state-text($Color-Neutral-2, 1);



  }
}

.input-style-button[type="checkbox"]:checked ~ .toggle-container-button .label-style-button {
  left: 0px;
  
  
}

.input-style-button[type="checkbox"]:checked ~ .toggle-container-button .label-style-button .on2 {
  @include state-text($Color-Black-800, 1);
    margin-left: 0px;
    
   
    
}

.input-style-button[type="checkbox"] ~ .toggle-container-button .label-style-button .on2 {
  @include state-text($Color-Neutral-2, 1);
  margin-left: -110px;
 
  
}

.input-style-button[type="checkbox"]:checked ~ .toggle-container-button .label-style-button .off2 {
  @include state-text($Color-Neutral-2, 1);
  margin-left: 50px;
  
  
}
.input-style-button[type="checkbox"] ~ .toggle-container-button .label-style-button .off2 {
  @include state-text($Color-Black-800, 1);
  
  
}

.input-style-button[type="checkbox"] ~ .toggle-container-button .label-style-button  {
  width: 110px;
  height: 44px;
  left: 117px;
  @include state-text($Color-Neutral-2, 1);
  
}
.input-style-button[type="checkbox"]:checked ~ .toggle-container-button .label-style-button  {
 left: 4px;
  width: 106px;
  height: 44px;

}

