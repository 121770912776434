.mt-25 {
    margin-top: 25px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.w-35 {
    width: 35%;
}

.w-37 {
    width: 37%;
}

.w-38 {
    width: 38%;
}

.w-23 {
    width: 23%;
}

.w-17 {
    width: 17%;
}


.w-2 {
    width: 2%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-22 {
    width: 22%;
}

.w-30 {
    width: 30%;
}

.w-32 {
    width: 32%;
}

.w-45 {
    width: 45%;
}

.w-10 {
    width: 10%;
}

.w-55 {
    width: 55%;
}

.w-60 {
    width: 60%;
}

.w-68 {
    width: 68%;
}

.w-70 {
    width: 70%;
}

.w-83 {
    width: 83%;
}

.w-40 {
    width: 40%;
}

.w-100 {
    width: 100%;
}

.mt-7 {
    margin-top: 7%;
}

.mt-15 {
    margin-top: 15%;
}

.mt-20 {
    margin-top: 20%;
}

.my-32 {
    margin-top: 32px;
    margin-bottom: 32px;
}

.mx-25 {
    margin-left: 25px;
    margin-right: 25px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-15 {
    margin-bottom: 15px;
}

.p-5-25 {
    padding: 5px 25px;
}

.p-15 {
    padding: 15px;
}

.p-0 {
    padding: 0;
}

.h-130 {
    height: 130px;
}

.h-30 {
    height: 30px;
}

.mt-11 {
    margin-top: 12%;
}

.ml-30 {
    margin-left: 30px;
}

.mr-25 {
    margin-right: 25px;
}

.tac {
    text-align: center;
}

.py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
}

.px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.px-69 {
    padding-right: 69px !important;

}

.me-38 {
    padding-right: 38px !important;
}

.me-36 {
    padding-right: 36px !important;
}

.pe-40 {
    padding-right: 40px !important;
}

.ps-40 {
    padding-left: 40px !important;
}

.me-10 {
    margin-right: 10px !important;
}

.me-40 {
    margin-right: 40px !important;
}

.ms-40 {
    margin-left: 40px !important;
}

.mb-6 {
    margin-bottom: 6px;
}

.w-49 {
    width: 49%;
}

.py-19 {
    padding-top: 19px !important;
    padding-bottom: 19px !important;
}

.py-23 {
    padding-top: 23px !important;
    padding-bottom: 23px !important;
}

.ps-sm {
    padding-left: 2px !important;
}
.p-2{
    padding: 2% 2% 2% 0 !important; 
}