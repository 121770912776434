.container-header-sondage {
    height: 112px;
    background-color: #fff;

    .header-sondage {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 100%;

        .title-sondage {
            font-size: 36px;
            font-family: 'Noir';
            color: #CACBCE;
        }

        .count-sondage {
            font-size: 36px;
            font-family: 'Noir';
            color: #272727;
        }
    }
}

.container-empty-list-sondage {
    margin-top: 15%;
}



.container-create-sondage {

    .container-bredcrumb-sondage {
        .title-bredcrumb-sondage {
            color: #fff;
            font-family: 'public';
            font-weight: 700;
        }

        .bredcrumb-sondage-right {
            height: 64px;
            align-items: center;

            .draft-bredcrumb-sondage {
                color: rgb(86, 147, 238);
                font-weight: 700;
                font-family: 'public';
                margin-right: 25px;
            }

            .btn-delete-bredcrumb-sondage {
                width: 125px;
                height: 100%;
            }

            .btn-disabled-share-bredcrumb-sondage {
                height: 100%;
                border: none;
                width: 125px;
                font-weight: 700;
                font-family: 'Public';
                background-color: #9DDBA9;
                color: #fff;

                &:disabled {
                    background-color: #EBECEE;
                    color: #CACBCE;
                    cursor: none;
                }
            }

        }
    }

    .main-create-sondage {
        margin-top: 64px;

        .container-header-create-sondage {
            background-color: #fff;

            .header-creacte-sondage {
                max-width: 1440px;
                height: 100px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title-header-creacte-sondage {
                    font-family: 'Noir';
                    color: #CACBCE;
                    font-size: 36px;
                }

                .btn-header-creacte-sondage {}
            }
        }

        .container-title-desc-sondage {

            .bg-white {
                background-color: #ffffff;
            }

            .bg-gray {
                background-color: #FBFBFD;
            }

            .container-input-sondage {
                height: 192px;
                display: flex;
                align-items: center;

                .input-sondage {
                    max-width: 1440px;
                    height: 100px;
                    margin: 0 auto;
                    width: 100%;

                    .title {
                        label {
                            font-size: 24px;
                            font-family: 'Noir';
                            margin-bottom: 16px;
                        }

                        .description-sondage {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .container-btns-choices-sondage--not-empty {
            max-width: 1440px;
            margin: 0 auto;
        }

        .container-btns-choices-sondage--empty {
            .container-btn-add-sondage {
                margin: 0 auto;
            }
        }

        .container-btns-choices-sondage {
            background-color: #fff;

            .container-btn-add-sondage {
                display: flex;
                max-width: 610px;
                flex-wrap: wrap;
            }
        }

        .container-card-choice-sondage {
            background-color: #fff;
            padding: 40px 0;

            .card-choice-sondage--closed {
                height: 110px;
                overflow: hidden;
            }

            .card-choice-sondage--opened {

            }

            .arrow-opened {
                transform: rotate(180deg);
            }
            .arrow-closed {
                transform: rotate(0deg);
            }

            .card-choice-sondage {
                border: 1px solid #EBECEE;
                max-width: 1440px;
                margin: 0 auto;
                min-height: 110px;
                .header-card-choice-sondage {
                    height: 110px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #EBECEE;
                    padding: 0 35px;

                    .title {
                        font-size: 24px;
                        font-family: 'Noir';

                        .title-question {
                            color: #CACBCE;

                            .count {
                                color: #272727;
                            }
                        }

                        .count-of-question {
                            color: #272727;
                        }
                    }

                    .actions {
                        display: flex;


                        button {
                            background-color: #F2F2F5;
                            border-radius: 2px;
                            margin: 0 4px;
                            width: 50px;
                            height: 50px;
                            border: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                background-color: #ebebeb;
                            }

                            .is-opened {
                                transform: rotate(180deg);

                            }

                            .is-closed {
                                transform: rotate(0deg);
                            }
                        }
                    }
                }

                .content-card-choice-sondage {
                    padding: 35px 35px 0 35px;

                    .wrapper-title-question {
                        display: flex;
                        justify-content: space-between;
                        .title-question-choice {
                            width: 45%;

                            label {
                                font-family: 'Public';
                                font-weight: 700;
                            }

                            input {
                                padding-left: 0;
                            }

                            input::placeholder {
                                color: #272727 !important;
                                font-family: 'Public';
                            }
                        }

                        .btn-facultatif {}
                    }

                    .wrapper-question-input {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 54px;

                        .question-input {
                            width: 45%;

                            .checkbox-input {
                                display: flex;
                                align-items: center;

                                span {
                                    margin-left: 10px;
                                    font-family: 'Public';
                                    font-weight: 700;
                                }
                            }

                            .input-response {
                                margin-left: 30px;
                                padding-left: 0;

                                &::placeholder {
                                    color: #272727 !important;
                                }
                            }
                        }
                    }
                }

                .footer-card-choice-sondage {
                    padding: 0 35px 35px;
                    display: flex;
                    justify-content: end;
                    margin-top: 32px;

                    .btn-Secondary {
                        margin-right: 12px;
                    }
                }
            }
        }
    }
}


.container-modal-share-sondage {
    padding: 56px 102px 20px 102px;

    .copy-link-sondage {
        cursor: pointer;
    }

    .shared-social-media {
        .share-txt {
            font-family: 'Public';
            font-weight: 700;
            margin-right: 15px;
        }

        button:nth-child(3) {
            margin: 0 5px;
        }
    }

    .header-modal-sondage {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }


    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: #2196F3;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}