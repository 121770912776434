@import "../Base/typography";
@import "../Base/variables";

.input-form-control {
  position: relative;

  span {
    position: absolute;
    top: 12px;
    right: 8px;
    height: 100%;
    cursor: pointer;
    color: $Color-Black-800;

    &:disabled {
      color: $Color-Neutral-3 !important;
      cursor: context-menu;
    }
  }
}

.input-form-control-community {
  position: relative;
  padding-left: 40px;
  span {
    position: absolute;
    top: 12px;
    left: 8px;
    height: 100%;
    cursor: pointer;
    color: $Color-Black-800;

    &:disabled {
      color: $Color-Neutral-3 !important;
      cursor: context-menu;
    }
  }
}

.input-form-control-assistant {
  position: relative;

  .container-eye {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    cursor: pointer;
    color: $Color-Black-800;

    &:disabled {
      color: $Color-Neutral-3 !important;
      cursor: context-menu;
    }

    span {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }
  }
}

.input-form-control-welcome {
  position: relative;

  span {
    position: absolute;
    top: 8px;
    right: 12px;
    cursor: pointer;
    color: $Color-Neutral-3;

    &:disabled {
      color: $Color-Neutral-3 !important;
      cursor: context-menu !important;
    }
  }
}

.input-form-control-welcome-tag {
  position: relative;

  span {
    position: absolute;
    top: 8px;
    right: 12px;
  }
}

.error-input {
  @extend .form-error;
  color: $Color-Red-800 !important;
}

.error-input2 {
  @extend .form-error;
  padding-top: 6px;
  color: $Color-Red-800 !important;
}

.input-welcome {
  @extend .form-default-welcome;
  background-color: $Color-White !important;
  color: $Color-Black-800 !important;
  border: 2px solid transparent;
  border-bottom: 2px solid $Color-Neutral-3 !important;
  border-radius: 0px;
  border: thick;

  &.error {
    border: thick;
    border-bottom: 2px solid $Color-Red-800 !important;

    &:hover {
      border: thick;
      border-bottom: 2px solid $Color-Red-800 !important;
    }

    &:focus {
      border: thick;
      border-bottom: 2px solid $Color-Red-800 !important;
    }
  }

  &:hover {
    border: thick;
    border-bottom: 2px solid $Color-Black-800 !important;
  }

  &:focus {
    border: thick;
    background-color: transparent;
    border-bottom: 2px solid $Color-Black-800 !important;
    box-shadow: none;
  }

  &:disabled {
    border-bottom: 2px solid $Color-Neutral-6 !important;
    color: $Color-Neutral-4 !important;
    border: thick;
    border: 2px solid transparent;

    &:hover {
      border: thick;
      border: 2px solid transparent;
    }
  }

  &::placeholder {
    @extend .form-default-welcome;
    color: $Color-Neutral-3 !important;
  }
}

.input {

  border: 1px solid transparent;
  box-sizing: border-box;
  height: 49px !important;

  &.medium {
    height: 36px !important;
    padding-right: 8px !important;
  }

  &.text-default {
    &.success-form {
      background-color: #f0faf2 !important;
    }

    &.success {
      background-color: #f0faf2 !important;
    }

    @extend .form-default;
    background-color: $Color-Neutral-5 ;
    color: $Color-Black-800 !important;

    &.error {
      background-color: $Color-Red-200 !important;
      color: $Color-Black-800;

      &:hover {
        border: 1px solid $Color-Red-600;
      }

      &:focus {
        background-color: $Color-Neutral-7 !important;
        border: 1px solid $Color-Red-800;
        box-shadow: none;
      }
    }

    &:disabled {
      background-color: $Color-Neutral-6 !important;
      color: $Color-Neutral-4 !important;
      border: 1px transparent;

      &:hover {
        border: 1px transparent;
      }
    }
  }

  &::placeholder {
    @extend .form-default;
    color: $Color-Neutral-3 !important;
  }

  &:hover {
    border: 1px solid $Color-Neutral-3;
  }

  &:focus {
    background-color: $Color-Neutral-7 !important;
    border: 1px solid $Color-Black-800;
    box-shadow: none;
  }

  &.text-complete {
    @extend .form-edit;
    color: $Color-Black-800 !important;
  }
}

.input-title {
  border: 2px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;
  width: 100%;
  font-family: $FONT_FAMILY_NOIR;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  color: $Color-Black-800;
  background-color: transparent;

  &::placeholder {
    color: $Color-Neutral-4;
  }

  &:hover {
    border-bottom: 2px solid $Color-Neutral-4;
  }

  &:focus {
    border-bottom: 2px solid $Color-Black-800;

    &:hover {
      background-color: $Color-White;
    }

  }

  transition: border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out,
  -webkit-box-shadow 0.15s ease-in-out;
}

.input-discussion {
  @extend .body-md;

  max-height: 160px;
  border: none;
  outline: none;
  width: 100%;
  color: $Color-Neutral-2;
  background-color: transparent;
  resize: none;
  padding-right: 75px;

  &::placeholder {
    color: $Color-Neutral-3;
  }
}

textarea::placeholder {
  overflow: hidden;
}

.textaria-textField {
  @extend .body-md;

  padding-right: 8px;
  display: flex;
  width: 100%;
  border-radius: 0px !important;
  background-color: $Color-White;
  padding-left: 0;
  color: $Color-Black-800 !important;
  border: none;
  border-bottom: 1px solid $Color-Neutral-5;
  overflow-y: hidden;

  &:hover {
    overflow-y: overlay;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: $Color-Neutral-3 !important;
  }

  &:focus {
    box-shadow: none;
    border-color: $Color-Green-800;
  }
}

.textaria-patagraph {
  @extend .body-md;

  padding-right: 8px;
  display: flex;
  width: 100%;
  background-color: $Color-White;
  padding-left: 0;
  color: $Color-Black-800 !important;
  border: none;
  overflow-y: hidden;


  &.chat {
    max-height: 85px;
  }

  &:hover {
    overflow-y: overlay;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: $Color-Neutral-3 !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.comment-reply {
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

  &.pic {
    max-width: 36px;
    border-radius: 25px;
  }

  &.input {
    @extend .body-sm;

    background-color: $Color-Neutral-5;
    border-radius: 20px;
    height: 36px !important;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 8px !important;
    width: 100%;

    &::placeholder {
      @extend .body-sm;

      color: $Color-Neutral-3 !important;
      align-items: center;
      margin: auto;
    }

    &:focus {
      @extend .body-sm;

      background-color: $Color-Neutral-5 !important;
      color: $Color-Black-800 !important;
      box-shadow: none;
    }
  }
}

.select-placeholder {
  @extend .form-default;

  color: $Color-Neutral-3!important;
}

.comment {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: auto;
  @extend .body-sm;
  background-color: $Color-Neutral-5;
  border-radius: 20px;
  padding: 6px 14px;
  width: 100%;
  border: 1px solid transparent;
  box-sizing: border-box;

  &:hover {
    border: 1px solid $Color-Neutral-3;
  }

  &:focus {
    background-color: $Color-Neutral-7 !important;
    border: 1px solid $Color-Black-800;
    box-shadow: none;
  }

  transition: border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out,
  -webkit-box-shadow 0.15s ease-in-out;
}

.input-comments {
  @extend .body-sm;

  max-height: 85px;
  border: none;
  outline: none;
  width: 100%;
  color: $Color-Black-800;
  background-color: transparent;
  resize: none;
  padding-right: 8px;

  &::placeholder {
    color: $Color-Neutral-3;
  }

  overflow-y: hidden;

  &:hover {
    overflow-y: overlay;
  }
}

.input-date::-webkit-search-cancel-button,
.input-date::-webkit-clear-button {
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V10H20V21ZM20 8H4V5H20V8Z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  color: $Color-Black-800;
  cursor: pointer;
  height: 24px;
  margin-right: 0;
  width: 24px;
}

.input-date::-webkit-calendar-picker-indicator {
  color: $Color-Black-800;
  opacity: 1;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V10H20V21ZM20 8H4V5H20V8Z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 0px;
}

.input-time::-webkit-search-cancel-button,
.input-time::-webkit-clear-button {
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10.5 5H9V11L14.2 14.2L15 12.9L10.5 10.2V5Z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  color: $Color-Black-800;
  cursor: pointer;
  height: 24px;
  margin-right: 0;
  width: 24px;
}

.input-time::-webkit-calendar-picker-indicator {
  color: $Color-Black-800;
  opacity: 1;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10.5 5H9V11L14.2 14.2L15 12.9L10.5 10.2V5Z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 0px;
}

/********************  color-selector  ********************/
.rcp-light {
  --rcp-background: #ffffff;
  --rcp-input-text: #111111;
  --rcp-input-border: rgba(0, 0, 0, 0.1);
  --rcp-input-label: #717171;
  box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
  border-radius: 12px;
  background-color: transparent;
}

.rcp-fields {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.rcp-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
  width: 300px;
}

.rcp-fields-element {
  flex-direction: column-reverse !important;
}

.rcp-fields {
  flex-direction: row !important;
}

.rcp-fields-element-label {
  color: $Color-Black-800 !important;
  font-size: 12px;
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 700;
}

.color-picker {
  position: relative;

  &.selector {
    position: absolute;
    z-index: 999;
    bottom: 0;
  }

  &.button {
    position: relative;
    z-index: 10;
    width: 100%;
  }
}

.clip-path {
  clip-path: polygon(0% 0%,
      100% 0%,
      100% calc(100% - 10px),
      calc(50% + 10px) calc(100% - 10px),
      50% 100%,
      calc(50% - 10px) calc(100% - 10px),
      0% calc(100% - 10px));
  position: absolute;
  z-index: 10000;
  top: 0;
  height: 10px;
  background-color: $Color-White;
}

.carateristics textarea {
  height: auto;
  resize: none;
}

.MuiSelect-icon:dir(rtl) {
  color: $Color-Black-800 !important;
  right: auto !important;
  left: 12px !important;
}

.MuiSelect-icon:dir(ltr) {
  color: $Color-Black-800 !important;
  right: 12px !important;
}

.select-city-register {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url('./icon-down.png');
  background-repeat: no-repeat;
  background-position: 95% 20px;
  background-color: #F2F2F5;
  font-family: Public, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #EDEDED;
  padding-left: 12px;
  line-height: 24px;

}
.select-payment {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url('./icon-down.png');
  background-repeat: no-repeat;
  background-position: 95% 15px;
  background-color: #F2F2F5;
  font-family: Public, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #EDEDED;
  padding-left: 12px;
  line-height: 24px;

}

.select-form-register {
  select::-ms-expand {
    display: none;
  }

  .select-city-register {
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background: url('./icon-down.png');
    background-repeat: no-repeat;
    background-position: 95% 20px;
    background-color: #F2F2F5;
    font-family: Public, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    padding-left: 12px;
    line-height: 24px;

  }

  .option-placeholder {
    color: #B1B1B1
  }

  .option-choice {
    color: black
  }
}

.content-tooltip {
  font-family: $FONT_FAMILY_PUBLIC;
  font-weight: 100;
}

.link-tooltip {
  font-family: $FONT_FAMILY_PUBLIC;
}