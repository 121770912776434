@import "../Base/variables";

.footer {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  background-color: $Color-White;
  color: $Color-Black-800;
  box-shadow: inset 0 1px 0 #f5f5f6;
  min-height: 80px !important;
  padding: 16px 0;
}

@media (max-width: 1280px) {
  .second-s {
    margin: 0 64px;
  }
}

@media (max-width: 750px) {
  .fourth-s {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .third-s {
    justify-content: center !important;
    flex-direction: column;
  }
}

.first-s {
  max-width: 1200px;
}
