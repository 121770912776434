/** ************* Black & White ***************/

$Color-Black-1000: #000;
$Color-Black-800: #272727;
$Color-Black-600: #3d3d3d;
$Color-White: #fff;

/** ************* Grey ***************/

$Color-Neutral-2: #5a5a5a;
$Color-Neutral-3: #cacbce;
$Color-Neutral-4: #dadbdf;
$Color-Neutral-5: #f2f2f5;
$Color-Neutral-6: #f8f8fa;
$Color-Neutral-7: #fbfbfd;
$Color-Neutral-8: #fdfdfd;

/** ************* Green ***************/

$Color-Green-800: #9ddba9;
$Color-Green-600: #ceedd4;
$Color-Green-400: #e6f6e9;
$Color-Green-200: #f0faf2;

/** ************* Red ***************/

$Color-Red-800: #e06a6a;
$Color-Red-600: #f0b4b4;
$Color-Red-400: #f7dada;
$Color-Red-200: #fae9e9;

/** ************* Orange ***************/

$Color-Orange-800: #f5b95f;
$Color-Orange-600: #fadcaf;
$Color-Orange-400: #fdeed7;
$Color-Orange-200: #fef5e7;

/** ************* Blue ***************/

$Color-Blue-800: #5693ee;
$Color-Blue-600: #97befa;
$Color-Blue-400: #c2daff;
$Color-Blue-200: #d9e7fd;

/** ************* Purple ***************/

$Color-Purple-800: #8a57cc;
$Color-Purple-600: #b983ff;
$Color-Purple-400: #d4b4ff;
$Color-Purple-200: #e8d7fe;
