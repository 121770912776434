@import "../Base/variables";

.bg-with-border {
  border: 1px solid $Color-Neutral-5;
  border-radius: 3px;
  box-sizing: border-box;
  &.opened {
    border-radius: 3px 3px 0px 0px;
  }
  &.subliste {
    border-radius: 0px 0px 3px 3px;
    border-top: none;
  }
  &.dashed {
    border: 2px dashed $Color-Neutral-5;
  }
  &.border-buttom {
    border: none;
    border-radius: none;
    box-shadow: inset 0px -1px 0px #f5f5f6;
  }
  &.white {
    background-color: $Color-White;
  }
  &.unchecked {
    min-height: 64px;
    color: $Color-Neutral-5;
    border: 2px solid $Color-Neutral-5;
  }
  &.checked {
    min-height: 64px;
    color: $Color-Black-800;
    border: 2px solid $Color-Black-800;
  }
}
.bg-full-black {
  background-color: $Color-Black-800;
  border-radius: 3px;
}

.vignette-hover {
  transition: box-shadow 0.15s ease-out;;
  &:hover {
    box-shadow: 0 8px 12px rgba(195, 205, 206, 0.25);
  }
  &.dragAndDrop {
    cursor: pointer;
  }
}
.pointer {
  cursor: pointer;
}

.file-upload-student {
  background-color: $Color-Neutral-7;
  border: 1px solid $Color-Neutral-5;
  box-sizing: border-box;
  min-height: 80px;
}
.final-exam-passage {
  background: $Color-Neutral-7;
}

.sub-liste{
  box-shadow: 2px 6px 6px rgba(218, 218, 218, 0.25);
  background-color: $Color-White;
  border-radius: 3px;
  padding: 16px 0;
  z-index: 10;
}