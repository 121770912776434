@import "../Base/variables";
@import "../Base/typography";

.badge {
  @extend .body-sm;

  position: absolute;
  top: 12px;
  left: 12px;
  font-weight: 700 !important;
  padding: 2px 8px 2px 8px;
  border-radius: 3px;
  color: $Color-White;
  &.badge-primary {
    background-color: $Color-Blue-800;
  }
  &.badge-secondary {
    background-color: $Color-Neutral-3;
  }
  &.badge-success {
    background-color: $Color-Green-800;
  }
  &.badge-danger {
    background-color: $Color-Red-800;
  }
  &.badge-warning {
    background-color: $Color-Orange-800;
  }
  &.badge-info {
    background-color: $Color-Purple-800;
  }
  &.badge-light {
    background-color: $Color-White;
    color: $Color-Black-800;
  }
  &.badge-dark {
    background-color: $Color-Black-1000;
  }
}
.badge-user {
  @extend .body-sm;

  position: absolute;
  top: 12px;
  left: 12px;
  font-weight: 700 !important;
  padding: 2px 8px 2px 8px;
  border-radius: 3px;
  &.badge-primary {
    background-color: $Color-Blue-200;
    color: $Color-Blue-800;
  }
  &.badge-secondary {
    background-color: $Color-Neutral-3;
    color: $Color-Black-800;
  }
  &.badge-success {
    background-color: $Color-Green-200;
    color: $Color-Green-800;
  }
  &.badge-danger {
    background-color: $Color-Red-200;
    color: $Color-Red-800;
  }
  &.badge-warning {
    background-color: $Color-Orange-200;
    color: $Color-Orange-800;
  }
  &.badge-info {
    background-color: $Color-Purple-200;
    color: $Color-Purple-800;
  }
}

.notif-badge {
  @extend .Button-sm;

  color: $Color-White;
  background-color: $Color-Green-800;
  padding: 6px 16px;
  border-radius: 3px;
}

.badge-text {
  @extend .body-sm;

  font-weight: 700 !important;
  &.primary {
    color: $Color-Blue-800;
  }
  &.secondary {
    color: $Color-Neutral-3;
  }
  &.success {
    color: $Color-Green-800;
  }
  &.danger {
    color: $Color-Red-800;
  }
  &.warning {
    color: $Color-Orange-800;
  }
  &.info {
    color: $Color-Purple-800;
  }
  &.dark {
    color: $Color-Black-800;
  }
}
.badge-icon {
  border: 1px solid $Color-Neutral-5;
  border-radius: 50px;
  &.chapter-card {
    width: 64px;
    height: 64px;
  }
}

.icon-state {
  &.primary {
    color: $Color-Blue-800;
  }
  &.secondary {
    color: $Color-Neutral-3;
  }
  &.success {
    color: $Color-Green-800;
  }
  &.danger {
    color: $Color-Red-800;
  }
  &.warning {
    color: $Color-Orange-800;
  }
  &.info {
    color: $Color-Purple-800;
  }
  &.dark {
    color: $Color-Black-800;
  }
}

.nav-state {
  &-refused {
    color: $Color-Red-800;
    @extend .body-sm;

    font-weight: 700 !important;
  }

  &-enligne {
    color: $Color-Green-800;
    @extend .body-sm;

    font-weight: 700 !important;
  }
  &-enligne-prof {
    background-color: $Color-Green-200;

    @extend .body-sm;

    font-weight: 700 !important;
    height: 64px;
    min-width: 103px;
    width: 151px;
  }
  &-horsligne-prof {
    background-color: $Color-Neutral-3;

    @extend .body-sm;

    font-weight: 700 !important;
    height: 64px;
    min-width: 103px;
    width: 151px;
  }

  &-edit {
    color: $Color-Blue-800;

    @extend .body-sm;

    font-weight: 700 !important;
  }

  &-validation {
    color: $Color-Orange-800;

    @extend .body-sm;

    font-weight: 700 !important;
  }
}

.badge-withicon {
  @extend .Button-xl;
  text-transform: capitalize !important;
  width: fit-content;
  display: flex;
  padding: 6px 12px 6px 8px;
  border-radius: 40px;
  &.unvalide {
    color: $Color-Red-800;
    background-color: $Color-Red-200;
  }
  &.progres {
    color: $Color-Orange-800;
    background-color: $Color-Orange-200;
  }
  &.validated {
    color: $Color-Green-800;
    background-color: $Color-Green-200;
  }
  &.horsligne {
    color: $Color-Neutral-3;
    background-color: $Color-Neutral-5;
  }
}

.badge-withicon2 {
  padding : 2px, 8px, 2px, 8px;
  margin-left: 4px;
  width: 89px;
  height: 20px;
  display: flex;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &.primary {
    background: #D9E7FD;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;

    color: #3538CD;
  }
}

.badge-square {
  padding: 12px;
  border-radius: 3px;
  height: 48px;
  width: 48px;

  &.danger {
    background-color: $Color-Red-200;
    color: $Color-Red-800;
  }
  &.success {
    background-color: $Color-Green-200;
    color: $Color-Green-800;
  }
  &.waiting {
    background-color: $Color-White;
    color: $Color-Neutral-3;
    border: 1px solid $Color-Neutral-5;
    box-sizing: border-box;
    padding: 11px;
  }
  &.info {
    background-color: $Color-Orange-200;
    color: $Color-Orange-800;
  }
}

.test-state {
  padding: 64px;
  border-radius: 100px;
  height: 192px;
  width: 192px;

  &.refused {
    background-color: $Color-Red-200;
    color: $Color-Red-800;
  }
  &.success {
    background-color: $Color-Green-200;
    color: $Color-Green-800;
  }
  &.waiting {
    background-color: $Color-Neutral-5;
    color: $Color-Neutral-3;
  }
}

.diplom-state {
  padding: 64px;
  border-radius: 100px;
  height: 192px;
  width: 192px;
  background-color: $Color-Green-200;
}

.state-cours {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  &.certified {
    background-color: $Color-Green-200;
  }
  &.exam {
    background-color: $Color-Neutral-6;
    color: $Color-Neutral-4;
    &.done {
      background-color: $Color-Green-200;
      color: $Color-Green-800;
    }
    &.refused {
      background-color: $Color-Red-200;
      color: $Color-Red-800;
    }
  }
  &.locked {
    background-color: $Color-Neutral-6;
  }
  &.unlocked-inprogress {
    background-color: $Color-Neutral-5;
  }
}
.state-cours-icon {
  margin: 10px;
  &.small {
    margin: 8px;
  }
}

.threedots-small span {
  display: inline-block;
  border-radius: 50%;
  width: 4px !important;
  height: 4px !important;
  background-color: $Color-Neutral-4;
  &.inprogress {
    background-color: $Color-Black-800;
  }
}

.threedots-small {
  padding: 18px 10px;
}

.threedots-small span:nth-child(2) {
  margin-left: 4px;
  margin-right: 4px;
}

.badge-primary {
  @extend .body-sm;
  font-weight: 700 !important;
  padding: 2px 8px 2px 8px;
  border-radius: 3px;
  color: $Color-White;
  background-color: $Color-Green-800;
  height: max-content;

}

.badge-team {
  position: relative;
  font-size: 14px;
  font-weight: 700 !important;
  padding: 2px 8px 2px 8px;
  border-radius: 3px;
  color:#fbfbfd ;
  margin-left: 16px;
  
  text-align: center;
  &.admis{
    background-color: #9DDBA9;
    width: 59px;
    height: 24px;
  }
  &.waiting {
    background-color: #F5B95F;
    width: 87px;
    height: 24px;
  }
  &.suspended {
    background-color: $Color-Red-800;
    width: 87px;
    height: 24px;
  }
}

.badge-catalog-payment {
  position: relative;
  font-size: 14px;
  font-weight: 700 !important;
  padding: 2px 8px 2px 8px;
  border-radius: 3px;
  color:#fbfbfd ;
 
  
  text-align: center;
  &.free{
    background-color: #9DDBA9;
    width: 65px;
    height: 24px;
  }
  &.oneshot{
    background-color: #B983FF;
    width: 130px;
    height: 24px;
  }
  &.subscription{
    background-color: #F5B95F;
    width: 103px;
    height: 24px;
  }
  &.facilitated{
    background-color: #FA83EE;
    width: 158px;
    height: 24px;
  }
  &.mutiple{
    background-color: #4C6B8E;
    width: 208px;
    height: 24px;
  }
}

.badge-student {
  position: relative;
  font-size: 14px;
  font-weight: 700 !important;
  padding: 2px 8px 2px 8px;
  border-radius: 3px;
  color:#fbfbfd ;
  margin-left: 16px;
  
  text-align: center;
  &.admis{
    background-color: #9DDBA9;
    width: 59px;
    height: 24px;
  }
  &.suspended {
    background-color: #CACBCE;
    width: 87px;
    height: 24px;
  }
}
