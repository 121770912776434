.error-text {
  max-width: 521px;
  position: inherit;
  top: -50px;
  &.server {
    max-width: 577px;
    top: -30px;
  }
  &.error-403 {
    max-width: 595px;
  }
}

.error-img {
  max-width: 80vw;
  display: block;
  -webkit-user-drag: none;
  margin: auto;
  padding-top: 7.375em;
  &.server {
    padding-top: 6.375em;
  }
}

.error-description {
  position: relative;
}

@media (max-width: 390px) {
  .error-text {
    top: -10px;
    &.server {
      top: 0px;
    }
  }
  .error-img {
    padding-top: 4.375em;
    &.server {
      padding-top: 3.375em;
    }
  }
}
