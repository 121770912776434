

.notif-warning {
    background-color: $Color-Orange-200;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    &.small {
        width: auto!important;
    }
    
}

.notif-warning-card {
    background-color: #FEFCF9;
    width: 100%;
    padding: 16px;
    border: 1px solid #F5B95F;
    border-radius: 4px;
}

.notif-link {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
    font-weight: bold;
    text-decoration-thickness: 1px;
    color: #F5B95F;
}
.notif-suceess {
    background-color: $Color-Green-400;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    &.small {
        width: auto!important;
    }
}
.buttonmail {
    padding: 10px 16px;
    cursor: pointer;
    width: 249px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6F4B15;
    background: #ECDABF;
    border-radius: 4px;
}
.buttonmail2 {
    padding: 10px 16px;
    width: 249px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6F4B15;
    background: #ECDABF;
    border-radius: 4px;
}


.notif-fail {
    background-color: $Color-Red-400;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    &.small {
        width: auto!important;
    }
}