.threedots-large-Animation span {
  display: inline-block;
  background-color: #ccc;
  border-radius: 50%;
  width: 8px;
  height: 8px;

  &.small {
    width: 4px !important;
    height: 4px !important;
    animation-name: blink;
  }
  &.small-exam {
    width: 4px !important;
    height: 4px !important;
    animation-name: blink;
  }
  &.dark-small {
    width: 4px !important;
    height: 4px !important;
    animation-name: blink-dark;
  }
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: blink;
}
.threedots-large-Animation span:nth-child(2) {
  animation-delay: 0.1s;
  margin-right: 4px;
  margin-left: 4px;
  &.small-exam {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  &.small {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  &.dark-small {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
}
.threedots-large-Animation span:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes blink {
  0% {
    background-color: $Color-Neutral-4;
  }
  20% {
    background-color: $Color-Neutral-2;
    transform: scale(1.2);
  }
  100% {
    background-color: $Color-Neutral-3;
  }
}
@keyframes blink-dark {
  0% {
    background-color: $Color-Black-800;
  }
  20% {
    background-color: $Color-Black-600;
    transform: scale(1.2);
  }
  100% {
    background-color: $Color-Black-800;
  }
}
