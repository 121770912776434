// Import the reset first
@import "../node_modules/bootstrap/scss/bootstrap.scss";

// Import the base rules after
@import '~material-icons/iconfont/material-icons.scss';
@import './styles/koors.scss';

.MuiDialog-paperWidthMd {
    max-width: 792px !important;
}

.MuiDialog-paperWidthSm {
    max-width: 506px !important;
}

.MuiDialog-paperWidthLg {
    max-width: 1200px !important;
}

.MuiDialog-paperScrollBody {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 100%;
}

.MuiDialog-paperScrollPaper {
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    width: 100%;
}

a {
    text-decoration: none;
}

.MuiChip-root {
    color: #272727 !important;
    border: 2px solid #272727 !important;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    align-items: center;
    font-weight: bold;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent !important;
    @extend .body-sm;
}

.MuiChip-clickable:hover,
.MuiChip-clickable:focus {
    background-color: transparent;
}

.MuiChip-deleteIcon {
    color: #272727 !important;
    width: 22px;
    cursor: pointer;
    height: 22px;
    margin: 0 5px 0 -6px;
    -webkit-tap-highlight-color: transparent;
}

.transparent::before {
    border-bottom: none !important;
}

.transparent::after {
    border-bottom: none !important;
}

.first-letter-capitalize {
    text-transform: lowercase;
}

.first-letter-capitalize::first-letter {
    text-transform: uppercase;
}

#chatSidebarParent {
    z-index: 1;
    position: sticky;
    top: 64px;
    right: 0px;
}

.ql-editor {
    min-height: 292px !important;
}

.two-lines-paragraph {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    max-height: 56px;
}

.w-80 {
    width: 80%;
}

.player-wrapper {
    height: 502px;

    iframe {
        height: 502px !important;
    }
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    height: 502px;
}

.player {
    height: 502px !important;
}

#player {
    height: 502px !important;
}

.msg-list-empty {
    text-align: center;
    margin-bottom: 5%;
}

.course-validation-disabled {
    background-color: #ddd !important;
    cursor: not-allowed !important;
}

.hide-rest-txt {
    width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mr-5 {
    margin-right: 5px;
}

.horloge {
    align-items: flex-start;
    border: 0px none;
    border-radius: 3px;
    display: flex;
    gap: 10px;
    padding: 10px 12px 10px 10px;
    position: relative;
    width: fit-content;
  }
  
  .horloge .frame {
    align-items: center;
    display: flex;
    gap: 6px;
    height: 29px;
    position: relative;
    width: fit-content;
  }
  
  .horloge .div {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    position: relative;
    width: fit-content;
  }
  
  .horloge .frame-2 {
    align-items: flex-start;
    display: flex;
    gap: 3px;
    position: relative;
    width: fit-content;
  }
  
  .horloge .element-wrapper {
    align-items: flex-start;
    background-color: #f2f2f5;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 5px;
    position: relative;
    width: fit-content;
  }
  
  .horloge .element {
    color: #272727;
    font-family: "Public Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .horloge .h {
    color: #272727;
    font-family: "Public Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .horloge .text-wrapper {
    color: #272727;
    font-family: "Public Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .horloge .frame-3 {
    align-items: flex-start;
    display: flex;
    gap: 3px;
    position: relative;
    width: fit-content;
  }
  
  .horloge .div-wrapper {
    align-items: flex-start;
    background-color: #f2f2f5;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 5px;
    position: relative;
    width: fit-content;
  }
  
  .horloge .element-2 {
    color: #272727;
    font-family: "Public Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .horloge .mn {
    color: #272727;
    font-family: "Public Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  

.MuiAlert-standardSuccess {
    font-family: "Public", sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #272727 !important;
    background-color: #FFFFFF !important;
    border: 1px solid #F8F8FA !important;
    box-shadow: 0px 12px 16px -4px rgba(39, 39, 39, 0.08), 0px 4px 6px -2px rgba(39, 39, 39, 0.03) !important;
    border-radius: 4px !important;
}

.custom-swipeable-drawer-left {
    position: fixed;
    visibility:hidden;
    top: 0;
    left: -100%; /* Start off-screen */
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: left 0.3s ease-in-out;
}   
.custom-swipeable-drawer-right {
    position: fixed;
    visibility:hidden;
    top: 0;
    right: -100%; /* Start off-screen */
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: right 0.3s ease-in-out;
}   

.custom-swipeable-drawer-left.open {
    left: 0;
    visibility:visible;
    z-index:9999;
}
.custom-swipeable-drawer-right.open {
    right: 0;
    visibility:visible;
    z-index:9999;
}

.drawer-content {
    height:100%;
}

.catalog-search-bar {
    background-color:#F2F2F5;
    border-radius:8px;
    padding-left:44px;
    padding-right:16px;
}

.catalog-search-bar:focus-visible {
    outline-color: #272727;
}

.catalog-search-bar:-webkit-autofill,
.catalog-search-bar:-webkit-autofill:hover, 
.catalog-search-bar:-webkit-autofill:focus, 
.catalog-search-bar:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #F2F2F5 inset !important;
}

.catalog-search-bar-icon {
    position: relative;
    width: 100%;
}

.catalog-search-bar-icon:before {
    content: "";
    position: absolute;
    left: 10px;
    top: calc(50% - 12px);
    bottom: 0;
    width: 20px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg className='absolute' width='24' height='24' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2083 10.3333H11.4842L11.2275 10.0858C12.1258 9.04083 12.6667 7.68417 12.6667 6.20833C12.6667 2.9175 9.99917 0.25 6.70833 0.25C3.4175 0.25 0.75 2.9175 0.75 6.20833C0.75 9.49917 3.4175 12.1667 6.70833 12.1667C8.18417 12.1667 9.54083 11.6258 10.5858 10.7275L10.8333 10.9842V11.7083L15.4167 16.2825L16.7825 14.9167L12.2083 10.3333ZM6.70833 10.3333C4.42583 10.3333 2.58333 8.49083 2.58333 6.20833C2.58333 3.92583 4.42583 2.08333 6.70833 2.08333C8.99083 2.08333 10.8333 3.92583 10.8333 6.20833C10.8333 8.49083 8.99083 10.3333 6.70833 10.3333Z' fill='%23272727'/%3E%3C/svg%3E");
}