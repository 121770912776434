@import "../Base/variables";
@import "../Base/typography";

.component-chapters-progress {
  box-shadow: 1px 1px 0px #f5f5f6;
  width: 402px;
  background-color: $Color-White;
  transition: all 0.3s ease-out 0s;
  &.close {
    width: 105px;
    transition: all 0.3s ease 0s;
  }
}
.component-chapters-progress-sidebar {
  height: calc(100vh - 192px);
  overflow: hidden;
  &:hover {
    overflow-y: overlay !important;
    position: sticky;
    width: 402px;
  }
  &.close {
    &:hover {
      overflow-y: overlay !important;
      position: sticky;
      width: 105px;
    }
  }
}
.component-chapters-progress-sidebar::-webkit-scrollbar {
  z-index: 10000;
}
.component-chapters-progress-header {
  box-shadow: inset 0px -1px 0px #f5f5f6;
  display: flex;
  padding: 31px 24px;
  background-color: $Color-White;
  height: 128px;
}
.component-chapters-progress-header-secondary-cours-lvl1 {
  box-shadow: inset 0px -1px 0px #f5f5f6;
  display: flex;
  padding: 31px 24px 31px 88px;
  background-color: $Color-Neutral-5;
  height: 122px;
  position: relative;
  &:hover {
    background-color: $Color-Neutral-4;
    cursor: pointer;
  }
  &.open {
    background-color: $Color-White;
    &:hover {
      background-color: $Color-Neutral-6;
      cursor: pointer;
    }
  }
  &.locked {
    &:hover {
      background-color: $Color-Neutral-5;
      cursor: default;
    }
  }
}
.component-chapters-progress-header-secondary-cours-lvl2
{
  box-shadow: inset 0px -1px 0px #f5f5f6;
  display: flex;
  padding: 31px 24px 31px 88px;
  background-color: $Color-Neutral-7;
  height: 122px;
  position: relative;
  &:hover {
    background-color: $Color-Neutral-6;
    cursor: pointer;
  }
  &.open {
    background-color: $Color-White;
    &:hover {
      background-color: $Color-Neutral-6;
      cursor: pointer;
    }
  }
  &.locked {
    &:hover {
      background-color: $Color-Neutral-7;
      cursor: default;
    }
  }
}

.component-chapters-progress-header-secondary-path-open {
  position: absolute;
  bottom: -16px;
  z-index: 1000;
  left: 21px;
}

.component-chapters-progress-header-secondary-cours-state {
  position: absolute;
  right: 24px;
  top: 67px;
}
.component-chapters-progress-header-secondary-number {
  position: absolute;
  left: 32px;
  top: 45px;
}

.chapters-progress-button {
  transform: rotate(180deg);
}

.component-chapters-progress-header-column-info {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  animation-duration: 300ms !important;
  animation-iteration-count: 1 !important;
  animation-fill-mode: both !important;
  animation-name: openingFlex !important;
  &.close {
    display: none;
    animation-duration: 300ms !important;
    animation-iteration-count: 1 !important;
    animation-fill-mode: both !important;
    animation-name: closing !important;
  }
}

.component-chapters-progress-header-more {
  display: flex;
  animation-duration: 300ms !important;
  animation-iteration-count: 1 !important;
  animation-fill-mode: both !important;
  animation-name: closing !important;
  &.close {
    display: none;
    animation-duration: 300ms !important;
    animation-iteration-count: 1 !important;
    animation-fill-mode: both !important;
    animation-name: closing !important;
  }
}

.component-chapters-progress-header-column-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.component-chapters-progress-header-column-content-numbersGrey {
  color: $Color-Neutral-3;
}
.component-chapters-progress-chapters-list-chapter-skeleton {
  padding: 16px 32px 16px 24px;
  min-height: 122px;
  display: flex;
}

.component-chapters-progress-chapters-list-chapter {
  min-height: 122px;
  padding: 24px 32px 16px 88px;
  display: flex;
  position: relative;

  &.intro {
    min-height: 122px;
    padding: 24px 32px 16px 88px;
  }
  &:hover {
    background-color: $Color-Neutral-6;
    cursor: pointer;
  }
  &.close {
    padding: 0px;
  }
  &.locked {
    &:hover {
      background-color: $Color-White!important;
      cursor: default!important;
    }
  }
}

.component-chapters-progress-chapters-list-chapter-exam {
  box-shadow: inset 0px -1px 0px #f5f5f6;
  min-height: 122px;
  padding: 24px 32px 16px 88px;
  display: flex;
  position: relative;

  &:hover {
    background-color: $Color-Neutral-6;
    cursor: pointer;
  }
  &.locked {
    &:hover {
      background-color: $Color-White;
      cursor: default;
    }
  }
  &.close {
    padding: 0px;
  }
}

.component-chapters-progress-chapters-list-chapter-title {
  @extend .H4-Subtitle;

  color: $Color-Black-800;
  margin-bottom: 0px;
  animation-duration: 300ms !important;
  animation-iteration-count: 1 !important;
  animation-fill-mode: both !important;
  animation-name: openingFlex !important;
  &.locked {
    color: $Color-Neutral-3;
  }
}

.component-chapters-progress-chapters-list-chapter-exam-title {
  @extend .H4-Subtitle;

  color: $Color-Black-800;
  margin-bottom: 0px;
  animation-duration: 300ms !important;
  animation-iteration-count: 1 !important;
  animation-fill-mode: both !important;
  animation-name: openingFlex !important;

  &.locked {
    color: $Color-Neutral-3;
  }
}

.component-chapters-progress-chapters-list-chapter-duration {
  @extend .H4-Subtitle;

  color: $Color-Neutral-3;
  margin-bottom: 0px;
  animation-duration: 300ms !important;
  animation-iteration-count: 1 !important;
  animation-fill-mode: both !important;
  animation-name: openingFlex !important;
}

.component-chapters-progress-chapters-list-chapter-description {
  @extend .body-md;

  color: $Color-Neutral-2;
  margin: 0px;
  animation-duration: 300ms !important;
  animation-iteration-count: 1 !important;
  animation-fill-mode: both !important;
  animation-name: openingFlex !important;
}

.component-chapters-progress-chapters-list-chapter-progress-container {
  background: $Color-Neutral-4;
  height: 100%;
  position: absolute;
  top: 53px;
  left: 46px;
  width: 2px;
  z-index: 2;
  &.intro {
    height: 78%;
    top: 76px;
    &.hidden {
      transform: scaleY(0);
      transition: 250ms cubic-bezier(0.98, 0.02, 1, 0.74);
    }
  }
  &.diplom {
    top: 48px;
  }
}

.component-chapters-progress-chapters-list-chapter-progress-container::after {
  background: $Color-Black-800;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  transform: scaleY(0);
  transform-origin: 0 0;
  transition: transform 300ms ease-out 0ms;
  top: 0;
  left: 0;
}

.component-chapters-progress-description {
  z-index: 2;
  &.close {
    display: none !important;
  }
}

.component-chapters-progress-chapters-list-chapter:last-child
  .component-chapters-progress-chapters-list-chapter-progress-container {
  background: none;
  height: auto;
}

.component-chapters-progress-chapters-list-chapter-progress-icon {
  align-items: center;
  background: $Color-White;
  border: 3px solid $Color-Neutral-4;
  border-radius: 50%;
  display: flex;
  color: $Color-Neutral-4;
  height: 40px;
  justify-content: center;
  fill: $Color-Neutral-4;
  left: -19px;
  position: absolute;
  top: -24px;
  width: 40px;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  &.path-exam {
    top: 24px;
    left: 27px;
  }
}

.component-chapters-progress-chapters-list-chapter-progress-arc-left {
  fill: none;
  left: -4px;
  position: absolute;
  top: -4px;
  transform: rotate(0deg);
  transform-origin: 100% 50%;
  stroke: transparent;
  stroke-dasharray: 60px;
  stroke-dashoffset: 60px;
  transition: stroke-dashoffset 300ms ease-out 0ms;
  cursor: pointer;
  user-select: none;
}

.component-chapters-progress-chapters-list-chapter-progress-arc-right {
  fill: none;
  left: 15px;
  position: absolute;
  top: -4px;
  stroke: transparent;
  transform: rotate(0deg);
  transform-origin: 100% 50%;
  stroke-dasharray: 60px;
  stroke-dashoffset: 60px;
  transition: stroke-dashoffset 300ms ease-out 0ms;
  cursor: pointer;
  user-select: none;
}

// Current and done states
.component-chapters-progress-chapters-list-chapter-current::before {
  box-shadow: inset 0px 0px 8px 4px $Color-Neutral-6;
  background-color: $Color-White;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.component-chapters-progress-chapters-list-chapter-current-diplom::before {
  box-shadow: inset 0px 0px 8px 4px $Color-Neutral-6;
  background-color: $Color-White;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.component-chapters-progress-chapters-list-chapter-current-done-diplom::before {
  box-shadow: inset 0px 0px 8px 4px $Color-Neutral-6;
  background-color: $Color-White;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.component-chapters-progress-chapters-list-chapter-done
  .component-chapters-progress-chapters-list-chapter-progress-container::after {
  transform: none;
}
.component-chapters-progress-chapters-list-chapter-uncompleted
  .component-chapters-progress-chapters-list-chapter-progress-container::after {
  transform: none;
}

.component-chapters-progress-chapters-list-chapter-done
  .component-chapters-progress-chapters-list-chapter-progress-icon {
  background-color: $Color-Neutral-5;
  &.test {
    background-color: $Color-Green-200;
    &.refused {
      background-color: $Color-Red-200;
    }
  }
  &.exam {
    background-color: $Color-White;
  }
}

.component-chapters-progress-chapters-list-chapter-done
  .component-chapters-progress-chapters-list-chapter-progress-arc-svg {
  color: $Color-Black-800;
  &.test {
    color: $Color-Green-800;
    &.refused {
      color: $Color-Red-800;
    }
  }
}
.component-chapters-progress-chapters-list-chapter-progress-test-done {
  background-color: $Color-Green-200;
  width: 38px;
  height: 38px;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.component-chapters-progress-chapters-list-chapter-progress-test-refused {
  background-color: $Color-Red-200;
  width: 38px;
  height: 38px;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}

.component-chapters-progress-chapters-list-chapter-done-diplom
  .component-chapters-progress-chapters-list-chapter-progress-arc-svg,
.component-chapters-progress-chapters-list-chapter-current-done-diplom {
  fill: $Color-Green-800;
}

.component-chapters-progress-chapters-list-chapter-done-diplom
  .component-chapters-progress-chapters-list-chapter-progress-icon {
  background-color: $Color-Green-200;
}

.component-chapters-progress-chapters-list-chapter-current-exam-waiting
  .component-chapters-progress-chapters-list-chapter-progress-arc-left,
.component-chapters-progress-chapters-list-chapter-current-exam-waiting
  .component-chapters-progress-chapters-list-chapter-progress-arc-right {
  stroke-dashoffset: 0;
  stroke: $Color-Black-800;
}

.component-chapters-progress-chapters-list-chapter-uncompleted
  .component-chapters-progress-chapters-list-chapter-progress-arc-left,
.component-chapters-progress-chapters-list-chapter-current
  .component-chapters-progress-chapters-list-chapter-progress-arc-left,
.component-chapters-progress-chapters-list-chapter-current-close
  .component-chapters-progress-chapters-list-chapter-progress-arc-left,
.component-chapters-progress-chapters-list-chapter-done-diplom
  .component-chapters-progress-chapters-list-chapter-progress-arc-left,
.component-chapters-progress-chapters-list-chapter-done-diplom
  .component-chapters-progress-chapters-list-chapter-progress-arc-right,
.component-chapters-progress-chapters-list-chapter-current-done-diplom
  .component-chapters-progress-chapters-list-chapter-progress-arc-left,
.component-chapters-progress-chapters-list-chapter-current-done-diplom
  .component-chapters-progress-chapters-list-chapter-progress-arc-right,
.component-chapters-progress-chapters-list-chapter-done
  .component-chapters-progress-chapters-list-chapter-progress-arc-left,
.component-chapters-progress-chapters-list-chapter-done
  .component-chapters-progress-chapters-list-chapter-progress-arc-right {
  stroke-dashoffset: 0;
  stroke: $Color-Black-800;
  &.test {
    stroke: $Color-Green-800;
    &.refused {
      stroke: $Color-Red-800 !important;
    }
  }
  &.diplom {
    stroke: $Color-Black-800;
  }
}


.svg-container {
  position: absolute;
}

.loader-svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  fill: none;
  stroke-width: 4px;
  stroke-linecap: round;
  stroke: $Color-Black-800;
}

.animate {
  stroke-dasharray: 242.6;
  animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@keyframes fill-animation {
  0% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3;
  }
  100% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6;
  }
}

@keyframes closing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes opening {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes openingFlex {
  0% {
    opacity: 0;
    display: none ;
    height: 10px;
  }
  99% {
    opacity: 0;
    display: none ;
    height: 10px;
  }
  100% {
    opacity: 1;
    display: flex ;
  }
}
