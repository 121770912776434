

.img-profil {
  &.main {
    width: 48px;
    height: 48px;
    border-radius: 25px;
    object-fit: cover;

    &.responsive {
      width: 52px;
      height: 52px;
    }
  }

  &.small {
    width: 36px;
    height: 36px;
    border-radius: 25px;
    object-fit: cover;
  }

  &.xsmall {
    width: 32px;
    height: 32px;
    border-radius: 25px;
    object-fit: cover;
  }
}

.ordered-list {
  list-style-type: none;

  li {
    cursor: pointer;
  }
}

.auth-sidelogo {
  height: 100vh;
  width: 504px !important;
  background-image: url(../../imgs/imageauth.png);
  position: sticky;
  top: 0;
  background-size: cover;
  left: 0;
}

.auth-details {
  flex: 1;
  flex-grow: 1;
  padding-top: 80px;
  position: relative;
}

.auth-close {
  position: absolute;
  right: 32px;
  top: 32px;
}

.auth-form-logo {
  margin-bottom: 72px;
}

.auth-form {
  width: 348px !important;
}

@media (max-width: 1010px) {
  .auth-sidelogo {
    width: 400px !important;
  }
}

@media (max-width: 960px) {
  .auth-sidelogo {
    max-width: 300px !important;
  }
}

@media (max-width: 650px) {
  .auth-sidelogo {
    display: none !important;
  }
}

.paragraph-five-lines {
  max-height: 140px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

.grid-offer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(460px, 2fr));
  box-sizing: border-box;
  align-content: stretch;
}


@media (min-width: 1200px) {

  .profile-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    max-width: 1200px;
    margin: auto;
    align-items: center;
  }

  .detail-profile {

    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .delete-account {
    justify-content: end;
  }

  .second-action-profile {
    display: none;
  }

  .first-action-profile {
    display: flex;
  }
}

@media (max-width: 1200px) {
  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin-bottom: 24px;
  }

  .second-action-profile {
    display: none;
  }

  .delete-account {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .profile-info-component {
    width: 100% !important;
  }
}

@media (max-width: 700px) {

  .first-action-profile {
    display: none;
  }

  .second-action-profile {
    display: flex;
  }

  .delete-account {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .profile-edit-small,
  .email-edit-small,
  .password-edit-small,
  .password-edit-update {
    flex-direction: column !important;
  }

  .password-detail-edit-small {
    width: 100% !important;
  }
}

//component update profile info 
@media (max-width: 1200px) {
  .profile-info-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .profile-info-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}


@media (max-width: 1200px) {
  .profile-info-photo {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .profile-info-photo {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-right: 80px;
  }
}


@media (max-width: 1200px) {
  .update-profile-Info {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .update-profile-mail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }
}

@media (max-width: 900px) {
  .MuiDialog-paperScrollBody {
    width: auto !important;
  }
}


.bt-spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 6px solid #DADBDF;
  border-top: 6px solid #272727;

  -webkit-animation: 1s spin linear infinite;
  animation: 1.5s spin linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.style-progress-training {
  overflow-y: overlay;
}

.list-inscribed-school-title {
  font-family: 'NoirStd-Medium';
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #CACBCE;
}

.list-inscribed-school-name {
  font-family: 'NoirStd-Medium';
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #272727;
}

// 0 2 4 8 10 12 steps:navbar
$sizes: 0, 2, 4, 8, 10, 12;

@each $size in $sizes {
  #react-joyride-step-#{$size} {
    .__floater__open {
      top: -13px !important;
    }
  }
}


@mixin display-flex($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-align($position) {
  text-align: $position;
}

@mixin border($width, $type, $color) {
  border: $width $type $color;
}

.disconnect-payment-method-title-header {
  font-size: 24px !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
